import moment from "moment";
import React, { useMemo } from "react";
import { ScheduleData } from "src/api/reservation/reservation-type";
import { onChangeKoreanDays, numberToStringWithComma } from "src/utils/common-util";

type Props = {
  selectedSchedule: ScheduleData[];
};
const ReservationTimeAndPrice = ({ selectedSchedule }: Props) => {
  const price = useMemo(() => selectedSchedule.reduce((accumulator, time) => accumulator + time.price!, 0), [selectedSchedule]);
  const day = useMemo(() => onChangeKoreanDays(selectedSchedule[0].endDate), [selectedSchedule]);
  const seletedTime = useMemo(
    () =>
      selectedSchedule.map((item) => {
        return { startDate: item.startDate, endDate: item.endDate };
      }),
    [selectedSchedule],
  );

  const start = useMemo(() => moment.min(seletedTime.map((time) => moment(time.startDate))).format("HH:mm"), [seletedTime]);
  const end = useMemo(() => moment.max(seletedTime.map((time) => moment(time.endDate))).format("HH:mm"), [seletedTime]);
  const date = useMemo(() => moment.min(seletedTime.map((time) => moment(time.startDate))).format("M월 D일"), [seletedTime]);

  return (
    <div className="text-center font16 font-weight-400 mb10">
      <span className="">{`${numberToStringWithComma(price)}원`}</span>
      <span className="font15 font-weight-100 ml5">(부가세 별도)</span>
      <span className="mx5">|</span>
      <span>{date}</span>
      <span className="mx5">({day})</span>
      <span>{start}</span>
      {end && <span> ~ {end}</span>}
    </div>
  );
};

export default ReservationTimeAndPrice;
