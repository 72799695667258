import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getProviderData } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import MetaTag from "src/components/layout/MetaTag";
import { getUserDevice } from "src/utils/common-util";
import { route } from "src/vars";

/**
 * 마이페이지 메인 > 신청/계약 내역 > 상세 > 등록 완료 화면 (신용카드 등록, 변경)
 * /court/payment/result?bizType=payment&response=return&billId=6151&contractId=1030&pgcode=virtualaccount&providerId=
 */
const RegisterCreditcardComplete = () => {
  const { executeAsync: findSellerData } = useApiOperation(getProviderData);
  const navigate = useNavigate();
  const { contractId } = useParams();
  const location = useLocation();

  const device = getUserDevice();

  const query = useMemo(() => {
    return qs.parse(location.search, {
      allowDots: true,
      ignoreQueryPrefix: true,
    });
  }, [location.search]);

  const creditcardPath = `/court/mypage/contracts/${query.contractId}`;

  const paymentPath = `/court/mypage/contracts/${query.contractId}/bills/${query.billOrder}`;
  const myMain = route.myContracts;

  const resultList = useMemo(() => {
    return [
      {
        response: "PAYMENT_SUCCESS",
        title: "결제 완료",
        message: "결제가 완료되었습니다",
        path: paymentPath,
        icon: "",
      },
      {
        response: "PAYMENT_FAILED",
        title: "결제 실패",
        message: "결제 실패하였습니다.\n 잠시 후 다시 시도해 주세요.\n 문제가 계속되면 고객센터(#{contact})로 문의해 주세요.",
        path: paymentPath,
        icon: "",
      },
      {
        response: "PAYMENT_CANCEL",
        title: "결제 취소",
        message: "결제 시도가 취소되었습니다.",
        path: paymentPath,
        icon: "",
      },
      {
        response: "return",
        title: query.errorCode ? "등록 실패" : "등록 완료",
        message: query.errorCode ? "등록하실 카드 정보를 다시 확인하시어\n 잠시 후 다시 시도해 주세요." : "카드 등록이 완료되었습니다",
        path: creditcardPath,
        icon: "",
      },
      {
        response: "cancel",
        title: "등록 취소",
        message: "카드 등록이 취소되었습니다.",
        path: creditcardPath,
        icon: "",
      },
    ];
  }, [creditcardPath, paymentPath, query.errorCode]);

  const getSeller = useCallback(
    async (providerId: string) => {
      return await findSellerData({ providerId });
    },
    [findSellerData],
  );

  const resultItem = useMemo(() => {
    let item = resultList.find((item) => item.response === query.response);
    //고객센터 전화번호
    let contact = "";
    if (query.providerId) {
      const seller: any = getSeller(String(query.providerId));
      if (seller.data.data && seller.data.data.providerContactList) {
        const finded = seller.data.data.providerContactList.find((item: any) => item.assignedWorkType === "CS_PROVIDE");
        // console.log("finded", finded);
        if (finded) {
          contact = finded.contact;
        }
      }
    }

    if (item && query.response === "return") {
      if (query.bizType === "payment") {
        if (query.pgcode === "creditcard") {
          item.title = query.errorCode ? "결제 실패" : "결제 완료";
          item.message = query.errorCode ? String(query.payletterMessage) : "결제가 완료되었습니다";
          item.path = myMain.replace(":contractId", String(query.contractId));
        }
        if (query.pgcode === "virtualaccount") {
          item.title = query.errorCode ? "가상계좌 생성 실패" : "가상계좌 생성";
          item.message = query.errorCode ? String(query.payletterMessage) : "가상계좌 생성 완료되었습니다";
          item.path = myMain.replace(":contractId", String(query.contractId));
          if (query.providerId) {
            item.message = item.message.replace("#{contact}", contact);
          }
        }
      }
    }

    if (item && query.response === "cancel") {
      if (query.bizType === "payment") {
        item.title = query.errorCode ? "결제 취소 실패" : " 결제요청 취소";
        item.message = query.errorCode ? String(query.errorCode) : "결제 요청이 취소 되었습니다";
        item.path = myMain.replace(":contractId", String(query.contractId));
      }
    }
    // console.log("item.path", item);
    return item;
  }, [resultList, query, myMain]);

  const goToMyPage = () => {
    const path = resultItem?.path || "";
    // const path = "/court/mypage/main";
    navigate(path, { state: "mypage" });
  };
  return (
    <div>
      <form name="paymentCompleted">
        <MetaTag title="마이페이지" />
        <article className="announcement-page with-floating">
          <section>
            <div className="icon ic-check"></div>
            <div className="message-wrap">
              {/* <h2>등록 완료</h2> */}
              {/* <p>카드 등록이 완료되었습니다</p> */}
              <h2>{resultItem?.title}</h2>
              <p className="pre-formatted">{resultItem?.message}</p>
            </div>
            {device === "other" && (
              <div className="minmax500 px60 mt100">
                <button type="button" className="base-btn" onClick={goToMyPage}>
                  확인
                </button>
              </div>
            )}
          </section>
        </article>
        {device !== "other" && (
          <div className="base-floating-btn-wrap">
            <button type="button" className="base-btn" onClick={goToMyPage}>
              확인
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default RegisterCreditcardComplete;
