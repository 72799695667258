import {
  BuildingModel,
  BuildingParkingLotModel,
  SelectOption,
  TaapSpaceBuildingModel,
  exitDirectionOptions,
  heatingTypeOptions,
} from "src/api/building/building-types";
import { MetaData } from "src/api/public-types";
import { calculatePyeong, calculateRateOfUse, numberToStringWithComma } from "src/utils/common-util";

type Props = {
  building: TaapSpaceBuildingModel;
};

const BuildingPostDetail = ({ building }: Props) => {
  // 엘리베이터 표시

  const getElevatorText = (building: BuildingModel): string => {
    let elevatorTexts = [];
    if (Number(building?.passengerElev || 0) > 0) {
      elevatorTexts.push(`승객용 ${building?.passengerElev}대`);
    }
    if (building?.freightElev === 0 || Number(building?.freightElev || -1) > -1) {
      elevatorTexts.push(`화물용 ${building?.freightElev}대`);
    }
    return elevatorTexts.join(", ");
  };

  // 냉난방타입 표시
  const getHeatingTypeText = (building: BuildingModel | null): string => {
    if (!building?.heatingType || building.heatingType === "HEATING_UNRECOGNIZED") return "";

    let heatingTypeText = "";

    // 낸난방 방식 찾기
    const heatingTypeOption = heatingTypeOptions.find((option: SelectOption) => option.value === building?.heatingType);
    // if (!heatingTypeOption) return heatingTypeText;

    if (heatingTypeOption?.value === "DIRECT_HEATING_AIR_CONDITIONER") {
      // 직접입력 값 (냉난방타입설명)
      heatingTypeText = building?.heatingTypeDesc || "";
    } else {
      // 선택한 냉난방타입
      heatingTypeText = heatingTypeOption!.label || "";
    }
    return heatingTypeText;
  };

  // 주차장 표시
  const getParkingLotText = (parkingLots: BuildingParkingLotModel[]) => {
    const parkingLot = parkingLots[0];
    const selfPropelledParkingLotNums: number = parkingLot.selfPropelledParkingLotNums || 0;
    const mechanicalParkingLotNums: number = parkingLot.mechanicalParkingLotNums || 0;

    const selfParkingLotText = `지주식 ${selfPropelledParkingLotNums}`;
    const mechanicalParkingLotText = `기계식 ${mechanicalParkingLotNums}`;

    if (selfPropelledParkingLotNums > 0 && mechanicalParkingLotNums > 0) {
      return `${selfParkingLotText} / ${mechanicalParkingLotText}`;
    } else if (selfPropelledParkingLotNums > 0 && mechanicalParkingLotNums === 0) {
      return selfParkingLotText;
    } else if (selfPropelledParkingLotNums === 0 && mechanicalParkingLotNums > 0) {
      return mechanicalParkingLotText;
    } else {
      return "";
    }
  };

  return (
    <section className=" ">
      <div className="font13 mb20">
        <p>{building.description}</p>
      </div>
      {building?.leasableArea! > 0 && (
        <div className="info-line ">
          <span>기준층 임대면적</span>
          <p>
            {numberToStringWithComma(building.leasableArea, "0,0.00")}㎡ ({calculatePyeong(building.leasableArea)}평)
          </p>
        </div>
      )}

      {building?.leasableAreaNet! > 0 && (
        <div className="info-line">
          <span>기준층 전용면적</span>
          <p>
            {numberToStringWithComma(building.leasableAreaNet, "0,0.00")}㎡ ({calculatePyeong(building.leasableAreaNet)}평)
          </p>
        </div>
      )}

      {(building?.leasableArea! > 0 || building?.leasableAreaNet! > 0) && (
        <div className="info-line">
          <span>기준층 전용률</span>
          <p>{`${calculateRateOfUse(Number(building?.leasableAreaNet || 0), Number(building?.leasableArea || 0))} %`}</p>
        </div>
      )}

      <div className="info-line">
        <span>규모</span>
        <p>{`지상 ${building?.floorNums}층, 지하 ${building?.undergroundFloorNums}층` || "-"}</p>
      </div>

      {building?.buildingCommonFacility?.parkingLotList?.length! > 0 && (
        <div className="info-line">
          <span>주차</span>
          <p>{getParkingLotText(building.buildingCommonFacility!.parkingLotList!)}</p>
        </div>
      )}

      {(building.freightElev! > 0 || building.passengerElev! > 0) && (
        <div className="info-line">
          <span>엘리베이터</span>
          <p>{getElevatorText(building) || "-"}</p>
        </div>
      )}

      {building?.buildingToilet2List && building.buildingToilet2List.length > 0 && (
        <div className="info-line">
          <span>화장실</span>
          <div>
            {building?.buildingToilet2List?.map((metaData: MetaData, index: number) => {
              // 지하 or 지상
              const floorType = metaData.type === "BUILDING_TOILET_1" || metaData.type === "BUILDING_TOILET_3" ? "지하" : "지상";

              // 구분 (남여분리 or 남여공용)
              const type = metaData.type === "BUILDING_TOILET_1" || metaData.type === "BUILDING_TOILET_2" ? "남여분리" : "남여공용";

              // 갯수 (남1, 여1, 남여1)
              const count =
                type === "남여분리" ? `남${Number(metaData?.man || 0)}, 여${Number(metaData?.women || 0)}` : `남여${Number(metaData?.man || 0)}`;

              return (
                <p key={index} className="mb4">
                  {`${floorType} ${metaData.floor}층 / ${type} / ${count}`} <br />
                </p>
              );
            })}
          </div>
        </div>
      )}

      {building && building?.heatingType !== "HEATING_UNRECOGNIZED" && (
        <div className="info-line">
          <span>냉난방 방식</span>
          <p className="font14">{getHeatingTypeText(building)}</p>
        </div>
      )}

      <div className="info-line">
        <span>출입시간</span>
        <p>
          {building?.entryStartTime} ~ {building?.entryEndTime} {building?.isHoliday ? "/ 공휴일 가능" : ""}
        </p>
      </div>
      <div className="info-line">
        <span>주 출입구 방향</span>
        <p> {exitDirectionOptions.find((option: SelectOption) => option.value === building?.exitDirection)?.label}</p>
      </div>
      <div className="info-line">
        <span>건축물 용도</span>
        <p>{building?.buildingStructureType2List?.map((buildingStructure: MetaData) => buildingStructure.description).join(", ")}</p>
      </div>
      {building?.remodelingYmd && (
        <div className="info-line">
          <span>리모델링 년도</span>
          <p>{building.remodelingYmd || "-"}</p>
        </div>
      )}
    </section>
  );
};

export default BuildingPostDetail;
