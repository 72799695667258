// import moment from "moment";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getClosedDaysAsync, getMinMaxReservationTimeAsyc, postQuotationAsync } from "src/api/reservation/reservation-api";
import { AvailableReserveTimes, ClosedDay, ClosedParams, QuotationRequest, ScheduleData } from "src/api/reservation/reservation-type";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import TaapSpaceBuilding from "src/pages/building/components/TaapSpaceBuilding";
import SwiperViewer from "src/pages/components/SwiperViewer";
import { YmdFormat } from "src/utils/common-util";
import SwiperCore, { Navigation, Pagination, Parallax } from "swiper";
import useProductFacility from "../hooks/useProductFacility";
import useSchedule from "../hooks/useSchedule";
import FacilityReservation from "./components/FacilityReservation";
import FacilityUsageInfo from "./components/FacilityUsageInfo";
import ReservationTimeAndPrice from "./components/ReservationTimeAndPrice";
import { useNavigateGoBack } from "src/pages/hooks/navigate-go-back";
// import useSchedule from "./components/TestCom";

/**
 * 공용공간 상세
 */

SwiperCore.use([Parallax, Navigation, Pagination]);

const reCAPTCHAsiteKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY;

// 분 MM > HH 시간 MM 분 포맷으로 변경
const minutesToHHMM = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  if (hours === 0) {
    return `${mins}분`;
  } else if (mins === 0) {
    return `${hours}시간`;
  } else if (hours < 10) {
    return `${hours}시간 ${mins}분`;
  } else {
    return `${hours.toString().padStart(2, "0")}시간 ${mins.toString().padStart(2, "0")}분`;
  }
};

const FacilityDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { goBack } = useNavigateGoBack();
  const { productId, facilityId } = useParams();

  //예약구간 > 최대시간에 따른 slot 비활성화를 위해 따로 state 만듬

  // 휴일 목록 (하루종일)
  const [closedDays, setClosedDays] = useState<ClosedDay[]>([]);

  // 스케줄표에서 선택한 시간들
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleData[]>([]);

  const [notAvaliable, setNotAvailable] = useState(true);

  const [validateText, setValidateText] = useState("");

  const [userMinMaxTime, setUserMinMaxTime] = useState<AvailableReserveTimes>();

  // 상품/공용공간 상세 hook
  const { facility, product, building, mediaSorting } = useProductFacility({ productId: String(productId), facilityId: String(facilityId) });

  //공용공간 시간 slots 영역 커스텀 훅 > [요금, 휴무시간, 점유, 예약가능 구간]
  const { scheduleSlots, calendardate, setCalendarDate } = useSchedule({ product, facility });

  // scheduleSlots 에서 가장 낮은 금액
  const minimumPrice = useMemo(() => {
    return scheduleSlots.reduce((min, current) => (current.price && min.price && current.price < min.price ? current : min), scheduleSlots[0]);
  }, [scheduleSlots]);

  // 공용공간 휴무일 조회
  const { executeAsync: getClosedDays } = useApiOperation(getClosedDaysAsync, { noAuthenticationRequired: true });

  // 견적서 등록 api
  const { executeAsync: postQuotation } = useApiOperation(postQuotationAsync, { noAuthenticationRequired: true });

  //예약 가능시간 조회 > 최소 ~ 최대 예약해야함
  const { executeAsync: getMinMaxReservationTime } = useApiOperation(getMinMaxReservationTimeAsyc, { noAuthenticationRequired: true });

  useEffect(() => {
    if (selectedSchedule.length > 0) {
      setNotAvailable(false);
    } else {
      setNotAvailable(true);
      setValidateText("");
    }
    if (userMinMaxTime?.id && selectedSchedule.length > 0) {
      selectedMinMaxTimeValidate();
    }
  }, [selectedSchedule]);

  useEffect(() => {
    if (facility) {
      fetchMinMaxReserveTime();
    }
  }, [facility]);

  const fetchMinMaxReserveTime = async () => {
    const { data } = await getMinMaxReservationTime({
      serviceId: String(productId),
      serviceType: "SERVICE_PRODUCT",
      commonFacilityType: facility?.commonFacilityType || "COMMONFACILITY_UNRECOGNIZED",
      buildingCommonFacilityId: String(facilityId),
    });

    //여기까지 했었다 예약시간 불러오기
    if (data.data) {
      setUserMinMaxTime(data.data.content[0]);
    }
  };

  //예약시간 최소~최대 유효성 검사
  const selectedMinMaxTimeValidate = () => {
    let message = "";
    let isDisabled = false;
    const minTime = userMinMaxTime!.minimumReservationTime;
    const maxTime = userMinMaxTime!.maximumReservationTime;
    const selectedSlotStart = selectedSchedule[0].startDate;
    const selectedSlotEnd = selectedSchedule[selectedSchedule.length - 1].endDate;

    // 선택한 slot의 총 시간(분)
    const totalTime = moment(selectedSlotEnd).diff(selectedSlotStart, "minutes");

    // slot 1개당 30분
    if (minTime > 0 && minTime > totalTime) {
      isDisabled = true;
      message = `예약 시간이 부족해요. (${minutesToHHMM(minTime)})`;
    }

    if (maxTime > 0 && maxTime < totalTime) {
      message = `예약 시간을 초과했어요. (${minutesToHHMM(maxTime)})`;
      isDisabled = true;
    }
    setNotAvailable(isDisabled);
    setValidateText(message);
  };

  // 휴무일 목록
  const fetchClosedList = async (startDate: string, endDate: string) => {
    const closedParams: ClosedParams = {
      serviceId: productId || "",
      serviceType: "SERVICE_PRODUCT",
      buildingCommonFacilityId: facilityId || "",
      startDate: moment(startDate).format(YmdFormat.WITH_TIME_ZONE),
      endDate: moment(endDate).format(YmdFormat.WITH_TIME_ZONE),
      commonFacilityType: facility?.commonFacilityType || "COMMONFACILITY_UNRECOGNIZED",
    };
    const { data } = await getClosedDays(closedParams);
    if (data.data) {
      setClosedDays(data.data.content.filter((item) => item.isClosed));
    }
  };

  // 스케줄 슬롯 선택시
  const onSelectSchedules = (schedule: ScheduleData) => {
    const sortedSchedule = [...selectedSchedule, schedule].sort((a, b) => a.slot - b.slot); // 선택한 시간 slot 기준 으로 sorting

    const startSlot = sortedSchedule[0].slot; // 가장 빠른 시간
    const endSlot = sortedSchedule[sortedSchedule.length - 1].slot; // 가장 마지막 시간

    const getSchedule = scheduleSlots.filter((item) => item.slot >= startSlot && item.slot <= endSlot);

    // 비활성화 구간 선택될 시 선택 안되도록 적용
    if (getSchedule.some((item) => item.isClosed)) {
      setSelectedSchedule([schedule]);
      return;
    }

    setSelectedSchedule(getSchedule);

    if (selectedSchedule.length > 0 && selectedSchedule.find((item) => item.slot === schedule.slot)) {
      setSelectedSchedule([]);
    }
  };

  // 이용 신청하기 버튼 -> 견적서 등록
  const onAddQuotaion = async () => {
    const token = await grecaptcha.enterprise.execute(reCAPTCHAsiteKey!, { action: "court/quotation/create" });
    if (!token) return;

    const quotaionData: QuotationRequest = {
      token,
      quotationItemList: [
        {
          productId: String(product?.id),
          buildingCommonFacilityId: String(facility?.id),
          startDate: selectedSchedule[0].startDate, //

          endDate: selectedSchedule[selectedSchedule.length - 1].endDate,
        },
      ],
    };
    const { data, status } = await postQuotation(quotaionData);

    if (status >= 200 && status <= 299) {
      const result = data.data.content;
      navigate(`/court/quotation/${result.quotationNo}`);
    }
  };
  return (
    <>
      <MetaTag title="공간상세" isReCAPTCHA={selectedSchedule.length < 1 ? false : true} />
      <Header headerType="BACK" title={facility?.facilityName} rightArea={<Link to="/court/main" className="home"></Link>} />

      <div className="facility-detail">
        <SwiperViewer medias={mediaSorting(facility?.mediaList || [])} />
        <article className="facility-info">
          {/* 메인정보 > [헤더 이미지, 정원, 비용등]*/}
          <FacilityUsageInfo minimumPrice={minimumPrice} facility={facility} />

          {/* 예약부분 > [캘린더. 시간선택] */}
          {product?.productType === "TIME_COURT" && (
            <FacilityReservation
              date={calendardate}
              selectedDate={setCalendarDate}
              scheduleSlots={scheduleSlots}
              onSelectSchedules={onSelectSchedules}
              selectedSchedule={selectedSchedule}
              fetchClosedList={fetchClosedList}
              closedDays={closedDays}
              onResetSchedule={() => setSelectedSchedule([])}
              validateText={validateText}
            />
          )}

          {/* 공간정보 */}
          {facility?.spaceStyle !== "SPACE_STYLE_UNRECOGNIZED" && (
            <section className="facility-info__facility-info top-divider">
              <h2>공간 정보</h2>
              <div className="info-line">
                <span className="minmax90">시설안내</span>
                <p className="pre-formatted">{facility?.facilityList?.join(",")}</p>
              </div>
              <div className="info-line">
                <span className="minmax90">이용안내</span>
                <p className="pre-formatted">{facility?.description}</p>
              </div>
            </section>
          )}

          {/* 건물 정보 */}
          <TaapSpaceBuilding building={{ ...building }} isOverview />
        </article>

        {product?.productType === "TIME_COURT" ? (
          <div className="base-floating-btn-wrap border-top ">
            {/* <div>{drawTotalPrice && drawTotalPrice()}</div> */}
            {selectedSchedule.length > 0 && <ReservationTimeAndPrice selectedSchedule={selectedSchedule} />}

            <div className="flex-center gap-10">
              <button
                className="base-btn color-white"
                disabled={notAvaliable}
                style={{ width: "100px" }}
                onClick={() => {
                  const prevURL = process.env.REACT_APP_PPOINT_BASEURL + location.pathname;
                  const state = {
                    prevURL,
                  };
                  navigate(`/court/product/${productId}/inquiry?facilityId=${facilityId}`, { state });
                }}
              >
                문의하기
              </button>
              <button className="base-btn" disabled={notAvaliable} onClick={onAddQuotaion}>
                예약하기
              </button>
            </div>
          </div>
        ) : (
          <div className="base-floating-btn-wrap">
            <button
              className="base-btn"
              onClick={() => {
                const prevURL = process.env.REACT_APP_PPOINT_BASEURL + location.pathname;
                const state = {
                  prevURL,
                };
                navigate(`/court/product/${productId}/inquiry?facilityId=${facilityId}`, { state });
              }}
            >
              문의하기
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default FacilityDetail;
