import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { postPublicQuestionAsync, postQuestionAsync } from "src/api/question/question-api";
import { QuestionParams, SiteTypeEnum, changeVoCType } from "src/api/question/question-type";
import { useMemberInfoState } from "src/recoil/member/hook";
import { formatPhoneNumber, getEmailNickname, onlyNumber } from "src/utils/common-util";

import qs from "qs";
import { BaseTextInput, ConfirmModal } from "src/components";
import { BaseCheckbox } from "src/components/BaseCheckbox";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import useProductFacility from "src/pages/product/hooks/useProductFacility";
import { useNavigateGoBack } from "../hooks/navigate-go-back";

type OnlyUseFrontParams = {
  company: string;
  agreeOne: boolean;
  agreeTwo: boolean;
};

const reCAPTCHAsiteKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY;
// { setCloseModal, handleInquiry, csTypeId, product }: Props
const Inquiry = () => {
  const { fetchMemberMe, member, isMemberLoading } = useMemberInfoState();
  const location = useLocation();

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const facilityId = useMemo(() => qs.parse(location.search, { allowDots: true, ignoreQueryPrefix: true }).facilityId, [location.search]);

  const { productId } = useParams();
  const { facility, product } = useProductFacility({ productId: String(productId || ""), facilityId: String(facilityId || "") });

  const { executeAsync: postPublicQuestion } = useApiOperation(postPublicQuestionAsync, {
    noAuthenticationRequired: true,
  });
  const { executeAsync: postQuestion } = useApiOperation(postQuestionAsync);

  const defaultValues: QuestionParams & OnlyUseFrontParams = useMemo(() => {
    return {
      reporterName: "",
      reporterPhone: "",
      reporterEmail: "",
      company: "",
      description: "",
      contentType: "text/plain",
      csTypeId: product?.productType === "FULL_COURT" ? "11" : "13",
      agreeOne: false,
      agreeTwo: false,
    };
  }, [product?.productType]);

  const onSubmit = (data: QuestionParams & OnlyUseFrontParams) => {
    const { agreeOne, agreeTwo, company, description, ...rest } = data;

    const productName = `상품명 : ${product?.productName}`;
    const facilityName = `${facilityId ? "공용공간명 : " + facility?.facilityName + "\n" : ""}`;
    const state = location.state as { prevURL: string };
    const url = `링크 : ${state.prevURL}`;
    const companyName = `${company ? "업종/회사명:" + company + "\n" : ""}`;
    const newDescription = `문의내용\n${description}`;
    const subitTemplate = `${productName}\n${facilityName}${url}\n${companyName}\n${newDescription}`;

    if (member) {
      const addMemberParams = {
        ...rest,
        productId: product?.id,
        partnerId: product?.partnerId,
        description: subitTemplate,
        csCategoryType: "문의",
        site: SiteTypeEnum.SITE_TAAP_SPACE,
        csTypeName: changeVoCType(product?.productType === "FULL_COURT" ? "11" : "13"),
      };
      const { reporterName, reporterPhone, reporterEmail, ...memberPayload } = addMemberParams;
      fetchApi({ ...memberPayload, isSendAlarm: true });
    } else {
      const publicPayload = { ...rest, partnerId: product?.partnerId, productId: product?.id, description: subitTemplate };
      fetchApi({ ...publicPayload, isSendAlarm: true });
    }
  };
  const onError = () => {};
  const {
    handleSubmit,
    register,
    unregister,
    control,
    formState: { isValid, isDirty },
  } = useForm<QuestionParams & OnlyUseFrontParams>({
    defaultValues,
  });

  const fetchApi = useCallback(
    async (params: QuestionParams) => {
      if (!member) {
        const token = await grecaptcha.enterprise.execute(reCAPTCHAsiteKey!, { action: "court/cs/create" });
        if (!token) {
          return;
        }
        const { data, status } = await postPublicQuestion({ ...params, token });
        if (status >= 200 && status <= 299) {
          setIsAlertModalOpen(true);
        }
      } else {
        const { data, status } = await postQuestion({ ...params });
        if (status >= 200 && status <= 299) {
          setIsAlertModalOpen(true);
        }
      }
    },
    [member, postPublicQuestion, postQuestion],
  );
  const { goBack } = useNavigateGoBack();

  useEffect(() => {
    // register("company", {
    //   required: true,
    // });
    // register("description", {
    //   required: true,
    // });

    // v.1.13 반영 - harry 수정 > textArea trim 처림하여 space 입력 시 실제 데이터로 입력되는 것 방지
    register("description", {
      validate: {
        required: (value) => {
          if (value?.trim() === "") {
            return false;
          }
          return true;
        },
      },
    });
    if (!member) {
      // unregister("reporterName");
      unregister("reporterPhone");
      unregister("reporterEmail");
      unregister("agreeOne");
      unregister("agreeTwo");
    }
  }, [register, member, unregister]);

  useLayoutEffect(() => {
    fetchMemberMe();
  });

  if (isMemberLoading) return null;
  return (
    <div className="page-inquiry">
      <MetaTag isReCAPTCHA />
      <Header headerType="CLOSE" title={"문의접수"} onClickCloseButton={() => goBack()}></Header>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <article>
          <section>
            {member ? (
              <div className="gray-box">
                <div className="gray-box__line">
                  <p>작성자</p>
                  <p>{member.nickname || getEmailNickname(member.email)}</p>
                </div>
                <div className="gray-box__line">
                  <p>휴대폰</p>
                  <p>{formatPhoneNumber(member.phoneNumber)}</p>
                </div>
                <div className="gray-box__line">
                  <p>이메일</p>
                  <p>{member.email}</p>
                </div>
                <p className="text-right text-primary3 font12">이메일로 문의 답변을 보내드립니다.</p>
              </div>
            ) : (
              <>
                <div className="container">
                  <p className="index-title">작성자</p>
                  <Controller
                    control={control}
                    render={({ field: { name, value, onChange } }) => <BaseTextInput value={value} onChange={onChange} />}
                    {...register("reporterName", {
                      required: false,
                      shouldUnregister: true,
                    })}
                  ></Controller>
                </div>
                <div className="container">
                  <p className="index-title required">휴대폰</p>
                  <Controller
                    control={control}
                    {...register("reporterPhone", {
                      required: true,
                      shouldUnregister: true,
                    })}
                    render={({ field: { name, value, onChange } }) => (
                      <BaseTextInput
                        type="number"
                        value={value}
                        pattern="\d*"
                        maxLength={11}
                        onChange={(value) => {
                          if (value === "") {
                            onChange(onlyNumber(""));
                          } else if (value && value !== "e" && value?.length < 12) {
                            onChange(onlyNumber(value || ""));
                          }
                        }}
                      />
                    )}
                  ></Controller>
                </div>
                <div className="container">
                  <div className="flex-center-between">
                    <p className="index-title required">이메일</p>
                    <p className="font12 text-primary3">이메일로 문의 답변을 보내드립니다.</p>
                  </div>
                  <Controller
                    control={control}
                    {...register("reporterEmail", {
                      required: true,
                      shouldUnregister: true,
                    })}
                    render={({ field: { name, value, onChange } }) => <BaseTextInput value={value} type="email" onChange={onChange} />}
                  ></Controller>
                </div>
              </>
            )}
            <div className="container">
              <p className="index-title">업종/회사명</p>
              <Controller
                control={control}
                name="company"
                render={({ field: { name, value, onChange } }) => <BaseTextInput value={value} onChange={onChange} />}
              ></Controller>
            </div>
            <div className="container">
              <p className="index-title required">문의내용</p>
              <Controller
                control={control}
                name="description"
                render={({ field: { name, value, onChange } }) => <textarea value={value} onChange={onChange} maxLength={1000} />}
              ></Controller>
            </div>
            {!member && (
              <div className="container">
                <Controller
                  control={control}
                  {...register("agreeOne", {
                    required: true,
                    shouldUnregister: true,
                  })}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseCheckbox id={name} name={"agreement"} onChange={onChange}>
                      <p className="ml4 font14 font-weight-600">
                        <span className="mr4 text-primary4 font-weight-400">[필수]</span>만 14세 이상입니다
                      </p>
                    </BaseCheckbox>
                  )}
                ></Controller>
                <div className="flex-center-between mt20">
                  <Controller
                    control={control}
                    {...register("agreeTwo", {
                      required: true,
                      shouldUnregister: true,
                    })}
                    render={({ field: { name, value, onChange } }) => (
                      <BaseCheckbox id={name} name={"agreement"} onChange={onChange}>
                        <p className="ml4 font14 font-weight-600">
                          <span className="mr4 font13 text-primary4 font-weight-400">[필수]</span>개인정보 수집 및 이용 동의
                        </p>
                      </BaseCheckbox>
                    )}
                  ></Controller>
                  <a
                    href={"https://guidelines.taapspace.kr/terms/taapspace/privacy-policy/customer-support"}
                    style={{ height: "14px" }}
                    className="d-block icon-right-btn pr18 font15 ic-chevron-right icon-gray"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                  </a>
                </div>
              </div>
            )}
          </section>
          {isAlertModalOpen && (
            <ConfirmModal
              isOpen={true}
              btnRightTitle="확인"
              onClick={() => {
                goBack();
              }}
            >
              {<p className="font18 font-weight-600">문의가 접수되었습니다</p>}
            </ConfirmModal>
          )}

          <div className="base-floating-btn-wrap ">
            <button
              type="submit"
              className="base-btn" //
              disabled={!isDirty || !isValid}
            >
              문의하기
            </button>
          </div>
        </article>
      </form>
    </div>
  );
};

export default Inquiry;
