import Header from "src/components/layout/Header";
import { CashReceiptForm } from "../paymentDetails/CashReceiptForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getExpenseProofAsync, postExpenseProofAsync } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import moment from "moment";
import { YmdFormat } from "src/utils/common-util";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "src/pages/contract/contract-types";
import _ from "lodash";
import { ConfirmModal } from "src/components";

const CashBillInfo = () => {
  const { contractId } = useParams();
  const navigate = useNavigate();
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
    message: "",
    subMessage: "",
  });
  const [cashErrorTextFlag1, setCashErrorTextFlag1] = useState(false);
  const [cashErrorTextFlag2, setCashErrorTextFlag2] = useState(false);
  const [cashErrorTextFlag3, setCashErrorTextFlag3] = useState(false);
  // 지출 증빙 자료 조회
  const { executeAsync: getExpenseProof } = useApiOperation(getExpenseProofAsync);
  // 지출 증빙 자료 저장
  const { executeAsync: saveExpenseProof } = useApiOperation(postExpenseProofAsync);
  const [documentProofRequest, setDocumentProofRequest] = useState<any>({
    identityNum: "",
    mediaList: [],
    taxInvoiceEmail: "",
    taxInvoicePhone: "",
    taxInvoiceDate: null,
  });

  const errorInit: any = useMemo(() => {
    return {
      error: {
        identityNum: false,
        identityType: false,
        mediaList: false,
        taxInvoiceEmail: false,
        taxInvoicePhone: false,
        taxInvoiceDate: false,
      },
      format: {
        identityNum: false,
        identityType: false,
        mediaList: false,
        taxInvoiceEmail: false,
        taxInvoicePhone: false,
        taxInvoiceDate: false,
      },
    };
  }, []);
  const [proofError, setProofError] = useState<any>(_.cloneDeep(errorInit));

  // 신청/계약 상세 조회
  const fetchContractProof = useCallback(
    async (contractId: string) => {
      const { data: data2 } = await getExpenseProof({ contractId });

      if (data2.data.expenseProofRequest.mediaList !== null) {
        data2.data.expenseProofRequest.mediaList.forEach((dt: any) => {
          dt["cmdType"] = "U";
        });
        setDocumentProofRequest(data2.data.expenseProofRequest);
      }
    },
    [getExpenseProof],
  );
  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const handleClickExpenseProofCashBill = useCallback(
    async (handleClickExpenseProof: any) => {
      if (documentProofRequest.taxInvoiceDate !== null && documentProofRequest.taxInvoiceDate !== "") {
        documentProofRequest.taxInvoiceDate = moment(documentProofRequest.taxInvoiceDate).format(YmdFormat.YYYY_MM_DD) + "T00:00:00.000+09:00";
      }

      if (documentProofRequest.contractId !== null && Number(documentProofRequest.contractId) > 0) {
        documentProofRequest.cmdType = "U";
      } else {
        documentProofRequest.cmdType = "C";
      }

      const saveExpense = await saveExpenseProof({ contractId: String(contractId), expenseProofRequest: documentProofRequest });
      if (saveExpense.status > 199 && saveExpense.status < 400) {
        setAlertModal({
          ...alertModal,
          isOpen: true,
          message: "현금영수증 발행 정보를 수정하였습니다.",
          // subMessage: "요청하신 정보로 현금영수증 발행이 접수되었습니다.",
        });
        // onClose();
      }
    },
    [documentProofRequest, saveExpenseProof, contractId, alertModal],
  );

  useEffect(() => {
    if (contractId) {
      fetchContractProof(contractId);
    }
  }, [fetchContractProof, contractId]);

  return (
    <>
      <Header headerType="CLOSE" title="현금영수증 발급 정보" onClickCloseButton={onClose} />
      <div className="base-layout cash-bill-info-page">
        <div className="base-layout">
          <div className="index-title required">
            <p className="font15 font-weight-bold mb10 required">현금영수증 발급 정보</p>
          </div>

          <CashReceiptForm
            documentProofRequest={documentProofRequest}
            proofError={proofError}
            setDocumentProofRequest={setDocumentProofRequest}
            setCashErrorFlags={(flag1, flag2, flag3) => {
              setCashErrorTextFlag1(flag1);
              setCashErrorTextFlag2(flag2);
              setCashErrorTextFlag3(flag3);
            }}
            handleClickExpenseProofCashBill={handleClickExpenseProofCashBill}
          />
        </div>
        {alertModal.isOpen && (
          <ConfirmModal
            isOpen={alertModal.isOpen}
            btnRightTitle={"확인"}
            onClick={() => {
              setAlertModal({ ...alertModal, isOpen: false });
              onClose();
              // window.location.reload();
            }}
          >
            <div>
              <p className="font18 font-weight-600">{alertModal?.message}</p>
              <p className="font18">{alertModal?.subMessage}</p>
            </div>
          </ConfirmModal>
        )}
      </div>
    </>
  );
};

export default CashBillInfo;
