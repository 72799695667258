import { BuildingModel } from "../building/building-types";
import { HeatingType, MetaData, Sort, Status } from "../public-types";

// 공간상품 상태
export enum ProductStatus {
  ENABLED = "ENABLED", // 공개
  DISABLED = "DISABLED", // 비공개
  DRAFT = "DRAFT", // 이용중
  CANCELED = "CANCELED", // 관리자 등에 의해 사용중지(취소) 된 경우
  LINK_ENABLED = "LINK_ENABLED", // 링크공개
  EXPIRED = "EXPIRED", // 기간만료
}

// 공간상품 타입
export type ProductType = "FULL_COURT" | "OPEN_COURT" | "TIME_COURT" | "NON_RESIDENT_OFFICE" | "DIGITAL_PRODUCT";

export function getProductTypeText(productType?: ProductType) {
  switch (productType) {
    case "FULL_COURT":
      return "FULL COURT";
    case "OPEN_COURT":
      return "OPEN COURT";
    case "TIME_COURT":
      return "SERVICE COURT";
    case "NON_RESIDENT_OFFICE":
      return "비상주 오피스";
    default:
      return "";
  }
}

// 최소계약기간, 최대계약기간 으로 계약기간 표기하는 형태로 변환
export function getLeasePeriodText(minLeasePeriod?: number | string, maxLeasePeriod?: number | string): string {
  if (!minLeasePeriod || !maxLeasePeriod) return "";
  const minLeasePeriodNumber = Number(minLeasePeriod || 0);
  const maxLeasePeriodNumber = Number(maxLeasePeriod || 0);
  if (minLeasePeriodNumber === maxLeasePeriodNumber) {
    // 최소 최대 같을 경우 하나만 노출
    if (minLeasePeriodNumber % 12 === 0) {
      return minLeasePeriodNumber / 12 + "년";
    } else {
      return minLeasePeriodNumber + "개월";
    }
  } else {
    let period = "최소 ";
    if (minLeasePeriodNumber % 12 === 0) {
      period += minLeasePeriodNumber / 12 + "년";
    } else {
      period += minLeasePeriodNumber + "개월";
    }
    period += "~최대 ";
    if (maxLeasePeriodNumber % 12 === 0) {
      period += maxLeasePeriodNumber / 12 + "년";
    } else {
      period += maxLeasePeriodNumber + "개월";
    }
    return period;
  }
}

// 이용료 표기 형태로 변환
export function getRentalCostText(metaItem?: string) {
  switch (metaItem) {
    case "PRODUCT_RENT_MONTH_1":
      return "개월";
    case "PRODUCT_RENT_HOUR_1":
      return "시간";
    case "PRODUCT_RENT_MINUTE_10":
      return "10분";
    default:
      return "";
  }
}

// 이용료 할인 표기 형태로 변환
export function getRentalDiscountCostText(metaItem?: string) {
  switch (metaItem) {
    case "PRODUCT_RENT_DISCOUNT_MONTH_1":
      return "개월";
    case "PRODUCT_RENT_DISCOUNT_HOUR_1":
      return "시간";
    case "PRODUCT_RENT_DISCOUNT_MINUTE_10":
      return "10분";
    default:
      return "";
  }
}

// 공간상품 향 표기 형태로 변환
export function getProductDirectionText(metaItem?: string) {
  switch (metaItem) {
    case "PRODUCT_DIRECTION_E":
      return "동";
    case "PRODUCT_DIRECTION_W":
      return "서";
    case "PRODUCT_DIRECTION_S":
      return "남";
    case "PRODUCT_DIRECTION_N":
      return "북";
    case "PRODUCT_DIRECTION_SE":
      return "남동";
    case "PRODUCT_DIRECTION_SW":
      return "남서";
    case "PRODUCT_DIRECTION_NE":
      return "북동";
    case "PRODUCT_DIRECTION_NW":
      return "북서";
    default:
      return "";
  }
}

// 무료 인터넷 옵션 표기 형태로 변환
export function getFreeInternetOptionText(metaItem?: string) {
  switch (metaItem) {
    case "PRODUCT_FREE_INTERNET_1":
      return "랜선";
    case "PRODUCT_FREE_INTERNET_2":
      return "와이파이";
    default:
      return "";
  }
}

// 복합기 옵션 표기 형태로 변환
export function getPrinterOptionText(metaItem?: string) {
  switch (metaItem) {
    case "PRODUCT_PRINTER_1":
      return "출력";
    case "PRODUCT_PRINTER_2":
      return "복사";
    case "PRODUCT_PRINTER_3":
      return "스캔";
    case "PRODUCT_PRINTER_4":
      return "팩스";
    default:
      return "";
  }
}

// 난방타입 표기 형태로 변환
export function getHeatingTypeText(heatingType?: HeatingType, heatingTypeDesc?: string) {
  switch (heatingType) {
    case "CENTRAL_AIR_CONDITIONER":
      return "중앙난방";
      break;
    case "INDIVIDUAL_AIR_CONDITIONER":
      return "개별난방";
      break;
    case "DIRECT_HEATING_AIR_CONDITIONER":
      return heatingTypeDesc || "";
    default:
      return "";
  }
}

// 공조기 기기 표기 형태로 변환
export function getAirConditionerInfoText(metaItem?: string, value2?: string) {
  switch (metaItem) {
    case "PRODUCT_AIR_CONDITIONER_1":
      return `천장형 시스템 냉난방기 ${value2 || 0}대`;
    case "PRODUCT_AIR_CONDITIONER_2":
      return `냉난방기 ${value2 || 0}대`;
    case "PRODUCT_AIR_CONDITIONER_3":
      return `에어컨 ${value2 || 0}대`;
    case "PRODUCT_AIR_CONDITIONER_4":
      return `난방기 ${value2 || 0}대`;
    case "PRODUCT_AIR_CONDITIONER_5":
      return value2 || "";
  }
}

// 공간상품 - 안내 - 사용안내 타입
export type ProductGuideType =
  | "PRODUCT_GUIDE_UNRECOGNIZED" // 정의되지 않은 타입
  | "PRODUCT_GUIDE_PRINTER" // 복합기
  | "PRODUCT_GUIDE_WIFI_CUSTOMER" // 고객용 네트워크
  | "PRODUCT_GUIDE_WIFI_VISITOR" // 방문자용 네트워크
  | "PRODUCT_GUIDE_PARKINGLOT" // 주차
  | "PRODUCT_GUIDE_ETC"; // 기타

// 공간상품 기본정보
export interface ProductBasicInfoModel {
  productBuildingList: Array<ProductBuilding>; // 공간상품-건물 매핑 정보 ( 건물/호실 +공용공간 )
  buildingList?: Array<BuildingModel>; // 공간상품에 등록된 건물정보 ( 서버에서 내려주는 값 )
  productName?: string; // 공간상품이름
  introduce?: string; // 한줄소개
  productDirectionList?: Array<MetaData>; // 향
  description?: string; // 설명
  questionPhone?: string; // 문의연락처
  questionStartTime?: string; // 문의시작시간
  questionEndTime?: string; // 문의종료시간
  locationCode?: string;
}

// 공간상품 상품정보
export interface ProductInfoModel {
  providerId?: string; //프로바이더 ID
  afterStatus?: Status; //이용신청 후 비공개 처리 여부
  productType?: ProductType; //상품타입
  minLeasePeriod?: number | string; //최소계약기간
  maxLeasePeriod?: number | string; //최대계약기간
  moveInAvailDate?: string; //입주가능일자
  deposit?: number | string; //보증금
  earnest?: number | string; //계약금
  balanceFullPaymentDate?: number | string; //잔금일자
  saleDeposit?: number | string; //할인보증금
  saleEarnest?: number | string; //할인계약금
  isRightAwayMoveIn?: boolean; // 즉시 입주 여부
  rentalCostList?: Array<MetaData>; //이용료
  saleRentalCostList?: Array<MetaData>; //이용료 할인
  isInformPopup?: boolean; //이용료 안내팝업 설정여부
  informPopupDesc?: string; //이용료 안내팝업 내용
  isImpositionFee?: boolean; // 관리비 여부
  maintenanceFee?: number | string; //관리비
  productMaintenanceFeeList?: Array<MetaData>; //관리비 내역
  useNums?: number | string; //사용인원
  isDeskChairIncluded?: boolean; //책상_의자포함여부
  maxNums?: number | string; //계정 최대 인원
  totalSeatNums?: number | string; //총좌석수
  useStartTime?: string; //사용가능시작시간
  useEndTime?: string; //사용가능종료시간
}

// 공간상품 공용공간
export interface ProductCommonSpaceModel {
  parkingLotFreeDescription?: string; // 무료 주차 설명
  parkingLotPayDescription?: string; // 유료 주차 설명
  // meetingRoomMaxReservTimeMonthFlag?: boolean;
  meetingRoomMaxReservTimeMonth?: number; // 회의실 한달 최대 예약가능 시간
  meetingRoomMaxReservTimeDay?: number; // 회의실 하루 최대 예약가능 시간
  meetingRoomDescription?: string; // 회의실 추가 설명
  deskMaxReservTimeMonth?: number; // 좌석 한달 최대 예약가능 시간
  deskMaxReservTimeDay?: number; // 좌석 하루 최대 예약가능 시간
  deskDescription?: string; // 좌석 추가 설명
  refreshRoomMaxReservTimeMonth?: number; // 휴게공간 한달 최대 예약가능 시간
  refreshRoomMaxReservTimeDay?: number; // 휴게공간 하루 최대 예약가능 시간
  refreshRoomDescription?: string; // 휴게공간 추가 설명
  isParkingLot?: boolean; // 주차 사용 여부
  isMeetingRoom?: boolean; // 회의실 사용 여부
  isRefreshRoom?: boolean; // 휴게공간 사용 여부
  isDesk?: boolean; // 좌석 사용 여부
}

// 공간상품 시설/서비스
export interface ProductFacilityModel {
  productAirConditioner?: ProductAirConditioner; // 공조기
  productToiletList?: Array<MetaData>; // 화장실
  productPrinter?: ProductPrinter; // 복합기
  productFreeInternet?: ProductFreeInternet; // 무료 인터넷
  productOptionalFacilityList?: Array<MetaData>; // 추가 시설/서비스
  etcService?: string; // 기타시설서비스
}

/* 
  공간상품 상세
*/
export interface ProductModel extends ProductBasicInfoModel, ProductInfoModel, ProductFacilityModel, ProductCommonSpaceModel {
  id: number;
  imagePath?: string;
  deposit?: string;
  rent?: string;
  address?: string;
  isDisplayed?: string;
  isDeleted?: string;
  createdBy?: string;
  createdDate?: string;
  modifiedBy?: string;
  modifiedDate?: string;
  buildingName?: string;
  productRentValue1: string;
  productRentType: string;
  status?: string;
  refreshRoomMaxReservTimeMonthIsFlag: boolean;
  refreshRoomMaxReservTimeDayIsFlag: boolean;
  isUsed: boolean;
  vocUrl: string;
  leasableAreaNet?: number;
  leasableArea?: number;
  partnerId: string;
  productionPrice?: number;
  productionPriceType?: string;
}

// 공간상품 상세 조회 response data
export interface ProductDetailType {
  content: ProductModel;
}

// 공간상품 기본정보 등록 - 공간상품-건물 매핑 정보 ( 건물/호실 +공용공간 )
export interface ProductBuilding {
  id: number | string; // 건물 id
  productBuildingFloorList?: Array<ProductBuildingFloor>; // 건물 층
  productBuildingCommonFacility?: ProductBuildingCommonFacility; // 공용공간
}

// 공간상품-건물 매핑 정보 ( 건물/호실 +공용공간 ) > 공용공간
export interface ProductBuildingCommonFacility {
  meetingRoomList?: Array<string>; // 건물 미팅룸 ID
  refreshRoomList?: Array<string>; // 건물 리프레쉬룸 ID
  deskList?: Array<string>; // 건물 1인 데스크 ID
}

// 공간상품 기본정보 등록 - 건물/호실 중 층
export interface ProductBuildingFloor {
  id: number;
  floorNum?: number;
  productBuildingRoomList: Array<ProductBuildingRoom>;
}

// 공간상품 기본정보 등록 - 건물/호실 중 층의 호실
export interface ProductBuildingRoom {
  id: number;
  isPrimary: boolean;
}

// 공간상품 시설/서비스 - 공조기,
export interface ProductAirConditioner {
  heatingType: HeatingType; // 난방타입
  heatingTypeDesc?: string; // 난방타입설명
  isAirConditionerProvided?: boolean; // 공조기제공여부
  airConditionerDescription?: string; // 공조기 추가 설명
  airConditionerInfoList?: Array<MetaData>; // 공조기 기기정보
}

// 공간상품 시설/서비스 - 복합기
export interface ProductPrinter {
  isPrinterProvided?: boolean; // 복합기 제공여부
  printerDescription?: string; // 복합기 추가 설명
  printerOptionList?: Array<MetaData>; // 복합기 옵션
}

// 공간상품 시설/서비스 - 무료인터넷
export interface ProductFreeInternet {
  isFreeInternetProvided?: boolean; // 무료인터넷 제공여부
  freeInternetDescription?: string; // 무료인터넷 추가 설명
  freeInternetOptionList?: Array<MetaData>; // 무료인터넷 옵션
}

export type SearchType =
  | "ALL"
  | "PRODUCT_NAME"
  | "ADDRESS"
  | "BUILDING_NAME"
  | "CREATED_BY"
  | "LOCATION_CODE"
  | "FACILITY_NAME"
  | "RESERVATION_ID"
  | "RESERVATION_GROUP_ID"
  | "SUBJECT"
  | "OFFICER_NAME"
  | "OFFICER_ID"
  | "REPORTER_NAME"
  | "ID"
  | "GROUP_ID";

export type ProductCategory = "PRODUCT_CATEGORY_NORMAL" | "PRODUCT_CATEGORY_MANAGEMENT";

export interface ProductListParams {
  page?: number;
  size?: number;
  sort?: Sort;
  keyword?: string[] | string; // 검색어
  status?: Status[]; // 노출 여부
  productType?: ProductType; //검색할 상품의 타입
  searchType?: SearchType;
  isUsed?: boolean;
  partnerId?: string[];
  partnerCode?: string;
  productCategory?: ProductCategory[];
  greaterThanDisplayEndDate?: string; // 해당 날짜 보다 노출 기간 종료 일시가 이후인 날짜 검색
  search001?: string;
  id?: string[];
  buildingCommonFacilityId?: string; // 건물-공용공간ID
}

export interface ProductListModel {
  id?: string;
  providerId?: string;
  partnerId?: string;
  introduce?: string;
  productCategory?: ProductCategory;
  mediaPath?: string;
  productName?: string;
  productType: ProductType;
  deposit?: number;
  productRentType?: string;
  productRentValue1?: string;
  address?: string;
  buildingName: string;
  createdBy?: string;
  modifiedBy?: string;
  createdDate?: string;
  modifiedDate?: string;
  status?: Status;
  isUsed?: Boolean;
  sido?: string;
  sigungu?: string;
  leasableArea?: string;
  leasableAreaNet?: string;
  bname?: string;
  buildingAddressSummary?: string;
  primaryImageUrl?: string;
  priceMin?: string;
  facilityMaxPeopleNums?: string;
}

export const productTypeToString = (productType: ProductType) => {
  switch (productType) {
    case "FULL_COURT":
      return "임대";
    case "TIME_COURT":
      return "예약";
    case "DIGITAL_PRODUCT":
      return "디지털";
    default:
      return "-";
  }
};

export interface PriceParams {
  providerId: string;
  serviceId: string;
  serviceType: string;
  startDate: string;
  endDate: string;
  buildingCommonFacilityId: string;
}

export interface PriceModel {
  price: number; //판매요금
  cost: number; // 원가
  startDate: string; //시작시간
  endDate: string; //종료시간
  priceRuled: string; // 슬롯 요금이 적용된 룰
  slot: number; // 요금 순서
  priceRuleData: string; //요금 룰 데이터
}
