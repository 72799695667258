import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { contractsProviderData, getProviderData, getQuotationList, getRefundInfo } from "src/api/contract/contract-api";
import { ContractModel, Quotation, ScheduleRecentBill } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { useToast } from "src/pages/hooks/toast";
import { CashReceiptSection } from "./components/paymentDetails/CashReceiptSection";
import { PaymentActionButton } from "./components/paymentDetails/PaymentActionButton";
import { checkProgressStatus } from "./components/utils";
import ServiceTypeGuide from "./components/ServiceTypeGuide";
import ServiceTypePaymentMethodSelector from "./components/ServiceTypePaymentMethodSelector";
import ServiceTypePaymentPriceInfo from "./components/ServiceTypePaymentPriceInfo";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  contract: ContractModel;
  recentBill: ScheduleRecentBill;
  paymentStatus: any;
  primaryQuotationName?: any;
  documentProofRequest: any;
  setDocumentProofRequest: any;
  billingContractBill: any;
  handleClickPaymentS2: (pgcode: string, documentProofRequest: any) => void;
  handleClickExpenseProofCashBill: (documentProofRequest: any) => void;
  proofError: any;
  setProofError: any;
};
const ServiceTypePaymentInfo = ({
  contract,
  paymentStatus,
  recentBill,
  primaryQuotationName,
  handleClickPaymentS2,
  documentProofRequest,
  setDocumentProofRequest,
  billingContractBill,
  handleClickExpenseProofCashBill,
  proofError,
  setProofError,
}: Props) => {
  // console.log("contract", contract);
  const navigate = useNavigate();
  const { contractId } = useParams();
  const [cashErrorTextFlag1, setCashErrorTextFlag1] = useState(false);
  const [cashErrorTextFlag2, setCashErrorTextFlag2] = useState(false);
  const [cashErrorTextFlag3, setCashErrorTextFlag3] = useState(false);
  const { openToast } = useToast();
  // 계약 견적서 조회 api
  const { executeAsync: patchContractQuotations } = useApiOperation(getQuotationList);
  const { executeAsync: findSnapshot } = useApiOperation(getRefundInfo);
  const { executeAsync: findProviderData } = useApiOperation(contractsProviderData);
  const { executeAsync: findSellerData } = useApiOperation(getProviderData);

  //S2 결제 수단 선택 대상 적용 - 기본값 카드 결제
  const [pgcode, setPgcode] = useState<string>(recentBill.pgcode);
  const [phoneNumber, setPhoneNumber] = useState<string>("050-3220-6515");
  const [paymentAgree, setPaymentAgree] = useState(false);

  const [isAsk, setIsAsk] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [quotation, setQuotation] = useState<any>({});
  const [sellerData, setSellerData] = useState<any>({});
  const [receiptBundle, setReceiptBundle] = useState<any>({});
  const [cashBillList, setCashBillList] = useState<any>([]);
  const [taxInvoiceList, setTaxInvoiceList] = useState<any>([]);
  const [refundInfo, setRefundInfo] = useState<any>(null);
  const [guideInfo, setGuideInfo] = useState<any>(null);
  //계약 상품의 환불정보 조회
  const fetchFindInfo = useCallback(
    async (contractId: string) => {
      const _guideInfo = await findSnapshot({ contractId: contractId, domain: "product", attrName: "productNotice" } as any);
      if (_guideInfo.status === 200 && _guideInfo.data.data && _guideInfo.data.data.content && _guideInfo.data.data.content.length > 0) {
        setGuideInfo(_guideInfo.data.data.content[0]);
      }
      const _refundInfo = await findSnapshot({ contractId: contractId, domain: "product", attrName: "refundInfo" } as any);
      if (_refundInfo.status === 200 && _refundInfo.data.data && _refundInfo.data.data.content && _refundInfo.data.data.content.length > 0) {
        setRefundInfo(_refundInfo.data.data.content[0]);
      }
    },
    [findSnapshot],
  );
  // 계약 견적서 조회
  const contractQuotationList = useCallback(
    async (quotationList: any) => {
      // console.log("quotationList", quotationList);
      //S2 계약의 견적서 id 정리
      let ids: string = "";
      let orderNumSorted = _.sortBy(quotationList, ["orderNum"]).reverse();

      orderNumSorted!.forEach((list: Quotation) => {
        if (ids !== "") {
          ids = ids + "," + list.quotationNo;
        } else {
          ids = list.quotationNo;
        }
      });

      const response: any = await patchContractQuotations({ quotationNo: ids });

      setQuotation(response.data.data.content[0].quotationItemList[0]);
      //견적서의 공용공간으로 건물의 공용공간 중 매핑되는 것을 처리
      // console.log("response", response.data.data.content[0].quotationItemList[0]);
      //
    },
    [patchContractQuotations],
  );

  const getSeller = useCallback(
    async (providerId: string) => {
      const seller: any = await findSellerData({ providerId });
      if (seller) {
        console.log("seller.data.data", seller.data.data);
        setSellerData(seller.data.data);
      }
    },
    [findSellerData],
  );
  const findProviderCs = useCallback(
    async (contractId: string) => {
      // TODO
      const csData: any = await findProviderData({ contractId, assignedWorkType: "CS_PROVIDE" });

      console.log("csData", csData.data.data.content);
      if (csData.data.data.content.length > 0) {
        await getSeller(csData.data.data.content[0].providerId);
        setPhoneNumber(csData.data.data.content[0].contact!);
      }
    },
    [findProviderData, getSeller],
  );

  useEffect(() => {
    contractQuotationList(contract.quotationList);
    // console.log("billingContractBill", billingContractBill);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // const isMobile = true;
    if (isMobile) {
      // mobile
      setIsMobile(true);
    } else {
      // desktop
      setIsMobile(false);
    }
    if (contract.contractId) {
      findProviderCs(String(contract.contractId));
      fetchFindInfo(String(contract.contractId));
    }
    // console.log("billingContractBill", billingContractBill);
    if (billingContractBill) {
      const bundle = billingContractBill.billList[0].originBill.receiptBundle;
      setReceiptBundle(bundle || null);

      setCashBillList(billingContractBill.billList[0].originBill.cashbillList || []);
      setTaxInvoiceList(billingContractBill.billList[0].originBill.taxInvoiceList || []);
    }
  }, [contract, findProviderCs, billingContractBill, setReceiptBundle, contractQuotationList, fetchFindInfo]);

  // 만료되지 않은 결제 정보 필터링
  const checkExpireFiltered = useMemo(() => {
    const today = moment();
    let showArr: any = [];

    if (paymentStatus.length === 0 && recentBill.billPayStatus === "PAYMENT_READY") {
      paymentStatus.forEach((status: any) => {
        if (status.virtualAccount !== null) {
          let limit = status.virtualAccount.expireDate + " " + status.virtualAccount.expireTime;
          const limitDate = moment(limit, "YYYYMMDD HHmm");
          if (moment(today).isSameOrBefore(limitDate)) {
            showArr.push(status);
          }
        }
      });
    } else {
      showArr = paymentStatus;
    }

    return showArr;
  }, [paymentStatus, recentBill]);

  // 계좌번호 복사 핸들러
  const onCopyAccount = useCallback(
    async (account: string) => {
      try {
        await navigator.clipboard.writeText(account);
        openToast({ content: "계좌번호가 복사되었습니다" });
      } catch (err) {
        openToast({ content: "문제가 발생했습니다" });
      }
    },
    [openToast],
  );

  return (
    <>
      <section className="base-section-layout top-divider">
        <div className="base-section-layout__container">
          <div className="base-section-layout__wrap mb30 pb0">
            <ServiceTypePaymentPriceInfo
              recentBill={recentBill}
              documentProofRequest={documentProofRequest}
              checkExpireFiltered={checkExpireFiltered}
              receiptBundle={receiptBundle}
              onCopyAccount={onCopyAccount}
            />
          </div>
        </div>
      </section>

      {contract.contractStep !== "APPLY_CANCEL" &&
        checkExpireFiltered.length > 0 &&
        recentBill?.pgcode === "virtualaccount" &&
        documentProofRequest.expenseProofType === "CASHBILL" && (
          <CashReceiptSection
            recentBill={recentBill}
            documentProofRequest={documentProofRequest}
            cashBillList={cashBillList}
            paymentAgree={paymentAgree}
            setPaymentAgree={setPaymentAgree}
            proofError={proofError}
            setDocumentProofRequest={setDocumentProofRequest}
            setCashErrorFlags={(flag1, flag2, flag3) => {
              setCashErrorTextFlag1(flag1);
              setCashErrorTextFlag2(flag2);
              setCashErrorTextFlag3(flag3);
            }}
            handleClickExpenseProofCashBill={handleClickExpenseProofCashBill}
          />
        )}
      {contract.contractStep !== "APPLY_CANCEL" &&
        checkExpireFiltered.length > 0 &&
        recentBill?.pgcode === "virtualaccount" &&
        documentProofRequest.expenseProofType !== "CASHBILL" && (
          <>
            <section className="base-section-layout top-divider">
              <div className="base-section-layout__container">
                <div className="base-section-layout__wrap mb30 pb0">
                  <div className="base-section-layout__title-wrap">
                    <h2>세금계산서</h2>
                  </div>
                  {taxInvoiceList.length === 0 && (
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">발행</p>
                      <p className="base-section-layout-value">발행 대기</p>
                    </div>
                  )}
                  {taxInvoiceList.length > 0 && (
                    <div className="base-section-layout-line">
                      <p className="base-section-layout-index text-primary3">발행</p>
                      {taxInvoiceList.map((tax: any, index: number) => {
                        console.log("tax", tax);
                        return (
                          <p className="base-section-layout-value" key={index}>
                            {checkProgressStatus(tax.progressStatus)}
                          </p>
                        );
                      })}
                    </div>
                  )}

                  <article>
                    <section className="tax-calculate">
                      <div className="container">
                        <div className="base-section-layout-line">
                          <p className="base-section-layout-index text-primary3">사업자 등록번호</p>
                          <p className="base-section-layout-value">{documentProofRequest.identityNum}</p>
                        </div>
                        <div className="base-section-layout-line">
                          <p className="base-section-layout-index text-primary3">수신 이메일</p>
                          <p className="base-section-layout-value">{documentProofRequest.taxInvoiceEmail}</p>
                        </div>
                        <div className="base-section-layout-line">
                          <p className="base-section-layout-index text-primary3">유선 전화번호</p>
                          <p className="base-section-layout-value">{documentProofRequest.taxInvoicePhone}</p>
                        </div>
                        <div className="base-section-layout-line">
                          <p className="base-section-layout-index text-primary3">계산서 작성일자</p>
                          <p className="base-section-layout-value">
                            {documentProofRequest.taxInvoiceDate && moment(documentProofRequest.taxInvoiceDate).format("YYYY-MM-DD")}
                          </p>
                        </div>
                      </div>
                      {(taxInvoiceList.length === 0 ||
                        taxInvoiceList[0].progressStatus === "TRANS_READY" ||
                        taxInvoiceList[0].progressStatus === "TEMP_SAVE") && (
                        <>
                          <div className="base-floating-btn-wrap px0">
                            <button
                              type="button"
                              className="base-btn color-white base-btn-cashbill"
                              onClick={() => {
                                navigate(`/court/mypage/contracts/${contractId}/TaxInvoiceInfoEdit`);
                              }}
                            >
                              발급 정보 수정
                            </button>
                          </div>
                        </>
                      )}
                    </section>
                  </article>
                </div>
              </div>
            </section>
          </>
        )}

      {checkExpireFiltered.length === 0 &&
        ["CONTRACT_ACCEPT", "USE_APPROVAL"].includes(contract?.contractStep!) &&
        recentBill?.isVerifiedPaymentKey === true &&
        recentBill?.billPayStatus === "PAYMENT_READY" && (
          <ServiceTypePaymentMethodSelector
            recentBill={recentBill}
            currentPgCode={pgcode}
            newPgCode={setPgcode}
            documentProofRequest={documentProofRequest}
            setDocumentProofRequest={setDocumentProofRequest}
            proofError={proofError}
            setProofError={setProofError}
          />
        )}
      <ServiceTypeGuide guideInfo={guideInfo} refundInfo={refundInfo} sellerData={sellerData} contractStatus={contract?.contractStep!} />
      {checkExpireFiltered.length === 0 &&
      recentBill?.billPayStatus === "PAYMENT_READY" &&
      ["CONTRACT_ACCEPT", "USE_APPROVAL"].includes(contract?.contractStep!) ? (
        <section className={`base-section-layout ${recentBill?.isVerifiedPaymentKey === true ? "top-divider" : ""}`}>
          <PaymentActionButton
            isVerifiedPaymentKey={recentBill.isVerifiedPaymentKey}
            isMobile={isMobile}
            isAsk={isAsk}
            contract={contract}
            phoneNumber={phoneNumber}
            primaryQuotationName={primaryQuotationName}
            pgcode={pgcode}
            documentProofRequest={documentProofRequest}
            handleClickPaymentS2={handleClickPaymentS2}
            setIsAsk={setIsAsk}
          />
        </section>
      ) : (
        <section className="base-section-layout top-divider" />
      )}
    </>
  );
};
export default ServiceTypePaymentInfo;
