import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProductModel, getRentalCostText, productTypeToString } from "src/api/product/product-types";
import { BaseBottomSheet } from "src/components";
import BaseShareButton from "src/components/BaseShareButton";
import { useToast } from "src/pages/hooks/toast";

import { calculatePyeong, getKoreanCostUnits, numberToStringWithComma } from "src/utils/common-util";
import BaseShowMore from "./BaseShowMore";
import { uniqueId } from "lodash";
import { BuildingFloorModel, BuildingModel, BuildingRoomModel } from "src/api/building/building-types";
import { findPrimaryBuilding, sortedByBuildingName, sortedPrimaryFirstBuilding } from "../../product-types";

type Props = {
  product: ProductModel | null;
};

/**
 * 공간상품 상세 > 타이틀 영역
 */
type DrawRooms = BuildingRoomModel & BuildingFloorModel;
const MainArea = ({ product }: Props) => {
  const getAllBuildingRooms = (building: BuildingModel) => {
    const allRooms: DrawRooms[] = [];
    building.buildingFloorList?.forEach((floor) => {
      const { floorName, floorNum } = floor;
      floor.buildingRoomList?.forEach((rooms) => {
        const room = { ...rooms, floorName, floorNum };
        allRooms.push(room);
      });
    });
    return allRooms;
  };

  const renderBuildingRooms = (building: BuildingModel) => {
    const allRooms = getAllBuildingRooms(building);
    const firstHalf = allRooms?.slice(0, 3);
    const secondHalf = allRooms?.slice(3);

    if (allRooms) {
      if (allRooms.length > 3) {
        return (
          <div key={uniqueId()}>
            {firstHalf?.map((room) => {
              return (
                <div className="mt16" key={uniqueId()}>
                  <p className="label">{`${room.floorNum}층 ${room.roomNum}호`}</p>
                  <div className="building-area-wrap__line" key={room.id + "roomList"}>
                    <p>
                      전용 :{`${numberToStringWithComma(Number(room.leasableAreaNet))}㎡ (${calculatePyeong(Number(room.leasableAreaNet), "")}평)`}
                    </p>
                    <p className="right">
                      임대 : {`${numberToStringWithComma(Number(room.leasableArea))}㎡ (${calculatePyeong(Number(room.leasableArea), "")}평)`}
                    </p>
                  </div>
                </div>
              );
            })}
            <BaseShowMore moreData={secondHalf} />
          </div>
        );
      } else if (allRooms.length <= 3) {
        return (
          <div key={uniqueId()}>
            {allRooms.map((room) => {
              return (
                <div className="mt16" key={uniqueId()}>
                  <p className="label">{`${room.floorNum}층 ${room.roomNum}호`}</p>
                  <div className="building-area-wrap__line" key={room.id + "roomList"}>
                    <p>
                      전용 :{`${numberToStringWithComma(Number(room.leasableAreaNet))}㎡ (${calculatePyeong(Number(room.leasableAreaNet), "")}평)`}
                    </p>
                    <p className="right">
                      임대 : {`${numberToStringWithComma(Number(room.leasableArea))}㎡ (${calculatePyeong(Number(room.leasableArea), "")}평)`}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <section className="product-info__main bottom-divider">
      <div className="main-wrap">
        <div className={`space-chip mr8 ${product?.productType === "TIME_COURT" ? "color-white" : ""}`}>
          {product?.productType ? productTypeToString(product?.productType) : "-"}
        </div>
        <BaseShareButton />
      </div>
      <h2 className="main-title">{product?.productName}</h2>
      <p className="description">{product?.introduce}</p>
      {product?.productType === "FULL_COURT" && (
        <>
          <div className="price-wrap">
            <div className="left-price">
              <div className="left-price__index">
                <p>보증금</p>
              </div>
              <div className="left-price__price">
                <p>
                  {getKoreanCostUnits(product?.deposit).cost ?? "-"}
                  <span>{getKoreanCostUnits(product?.deposit).unit ?? "원"}</span>
                </p>
              </div>
            </div>
            <div className="right-price">
              <div className="right-price__index">
                <p>이용료(부가세 별도)</p>
              </div>
              <div className="right-price__price">
                <p>
                  {getKoreanCostUnits(product?.productionPrice).cost ?? "-"}
                  <span>{getKoreanCostUnits(product?.productionPrice).unit ?? "원"}</span>
                  <span className="sub">{product?.productionPriceType ? `/1${getRentalCostText(product?.productionPriceType)}` : ""}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="additional-info">
            <div className="info-wrap">
              <div className="info-wrap__index">
                <p>계약기간</p>
              </div>
              <div className="info-wrap__value">
                <p>
                  최소 <span>{product?.minLeasePeriod}</span>개월 ~ 최대 <span>{product?.maxLeasePeriod}</span> 개월
                </p>
              </div>
            </div>
            <div className="info-wrap right">
              <div className="info-wrap__index">
                <p>입주 가능일</p>
              </div>
              <div className="info-wrap__value">
                <p>
                  {product?.isRightAwayMoveIn ? "즉시" : product?.moveInAvailDate ? moment(product?.moveInAvailDate).format("MM월 DD일") + " ~" : "-"}
                </p>
              </div>
            </div>
          </div>
          {product?.buildingList && (
            <>
              {sortedPrimaryFirstBuilding(sortedByBuildingName(product?.buildingList))?.map((building, buildingIndex) => {
                return (
                  <div className="building-area-wrap" key={building.id}>
                    <h3>{building.buildingName}</h3>
                    {renderBuildingRooms(building)}
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </section>
  );
};
export default MainArea;
