import moment from "moment";
import { VirtualAccount } from "./types";

const checkExpireText = (virtualAccount: VirtualAccount): string => {
  if (!virtualAccount) return "";

  const today = moment();
  const limit = `${virtualAccount.expireDate} ${virtualAccount.expireTime}`;
  const limitDate = moment(limit, "YYYYMMDD HHmm");

  if (moment(today).isSameOrAfter(limitDate)) {
    return "만료";
  }

  return moment(limit, "YYYYMMDD HHmm").format("YYYY-MM-DD HH:mm");
};

const checkProgressStatus = (progressStatus: string) => {
  let status = "대기";
  if (progressStatus === "TEMP_SAVE") {
    status = "임시저장";
  }
  if (progressStatus === "ISSUE_COMPLETE") {
    status = "발행완료";
  }
  if (progressStatus === "ISSUE_REVOKE") {
    status = "발행취소";
  }
  if (progressStatus === "TRANS_BEFORE") {
    status = "전송전";
  }
  if (progressStatus === "TRANS_READY") {
    status = "전송대기";
  }
  if (progressStatus === "TRANS_ONGOING") {
    status = "전송중";
  }
  if (progressStatus === "TRANS_SUCCESS") {
    status = "전송성공";
  }
  if (progressStatus === "TRANS_FAIL") {
    status = "전송실패";
  }
  if (progressStatus === "ISSUE_MANUAL") {
    status = "수기발행";
  }
  return status;
};

export { checkExpireText, checkProgressStatus };
