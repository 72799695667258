import { VirtualAccountInfoProps } from "../types";
import { checkExpireText } from "../utils";

export const VirtualAccountInfo = ({ payment, documentProofRequest, virtualAccount, billPayStatus, onCopyAccount }: VirtualAccountInfoProps) => {
  console.log("documentProofRequest", documentProofRequest.expenseProofType);
  return (
    <>
      <div className="horizontal-divider" />
      <div className="base-section-layout-line">
        <p className="base-section-layout-index text-primary3">결제수단</p>
        <p className="base-section-layout-value">가상계좌</p>
      </div>
      <div className="base-section-layout-line">
        <p className="base-section-layout-index text-primary3"></p>
        {documentProofRequest.expenseProofType === "CASHBILL" && (
          <p className="base-section-layout-value text-primary3 font14 font-weight-400">
            결제 익일에 현금영수증이 자동 발급되며
            <br />
            취소 및 환불은 영업일 기준 최대 7일 정도 소요됩니다.
          </p>
        )}
        {documentProofRequest.expenseProofType !== "CASHBILL" && (
          <p className="base-section-layout-value text-primary3 font14 font-weight-400">
            세금계산서 발행 및 수정은 영업일 기준으로
            <br />
            최대 7일 정도 소요됩니다.
          </p>
        )}
      </div>

      {billPayStatus !== "PAYMENT_SUCCESS" && (
        <div className="base-section-layout-line">
          <p className="base-section-layout-index text-primary3">계좌번호</p>
          <p className="base-section-layout-value text-underline cursor-pointer" onClick={() => onCopyAccount(virtualAccount.accountNo)}>
            {virtualAccount.bankName} {virtualAccount.accountNo}
          </p>
        </div>
      )}

      {billPayStatus === "PAYMENT_READY" && (
        <>
          <div className="base-section-layout-line">
            <p className="base-section-layout-index text-primary3">결제일시</p>
            <p className="base-section-layout-value">{checkExpireText(virtualAccount)}</p>
          </div>
        </>
      )}
    </>
  );
};
