import Taap from "src/pages/taap/Taap";
import NetworkFailure from "src/pages/errors/NetworkFailure";
import PageNotFound from "src/pages/errors/PageNotFound";
import SystemCheck from "src/pages/errors/SystemCheck";
import SystemError from "src/pages/errors/SystemError";
import PageNotAllowed from "src/pages/errors/PageNotAllowed";
import JoinComplete from "src/pages/join/JoinComplete";
import RegisterEmail from "src/pages/join/RegisterEmail";
import Login from "src/pages/login/Login";
import Root from "src/pages/Root";
import Main from "src/pages/main/Main";
import MyPageMain from "src/pages/mypage/main/MyPageMain";
import ProfileEdit from "src/pages/mypage/profile/ProfileEdit";
import EmailVerify from "src/pages/mypage/profile/email/EmailVerify";
import PhoneEdit from "src/pages/mypage/profile/phone/PhoneEdit";
import ContractDetail from "src/pages/mypage/contract/detail/ContractDetail";
import ContractList from "src/pages/mypage/contract/list/ContractList";
import ProductDetail from "src/pages/product/detail/ProductDetail";
import PublicProductGuideList from "src/pages/productGuide/list/PublicProductGuideList";
import PublicProductGuideDetail from "src/pages/productGuide/detail/PublicProductGuideDetail";
import VisitorDetail from "src/pages/visitor/detail/VisitorDetail";
import VisitorForm from "src/pages/visitor/form/VisitorForm";
import InvitationDetail from "src/pages/visitor/invitation/InvitationDetail";
import ContractApply from "src/pages/contract/apply/ContractApply";
import ContractComplete from "src/pages/contract/complete/ContractComplete";
import UserList from "src/pages/mypage/contract/user/list/UserList";
import UserInvite from "src/pages/mypage/contract/user/invite/UserInvite";
import UserInviteFail from "src/pages/mypage/contract/user/invite/UserInviteFail";
import ContractBillList from "src/pages/mypage/contract/bill/list/ContractBillList";
import ContractBillDetail from "src/pages/mypage/contract/bill/detail/ContractBillDetail";
import PaymentScheduleList from "src/pages/mypage/contract/paymentSchedule/PaymentScheduleList";
// import ContractPaymentList from "src/pages/mypage/contract/payment/list/ContractPaymentList";
// import ContractPaymentDetail from "src/pages/mypage/contract/payment/detail/ContractPaymentDetail";
// import PaymentComplete from "src/pages/mypage/contract/complete/PaymentComplete";
import RegisterCreditcardComplete from "src/pages/mypage/contract/complete/RegisterCreditcardComplete";
import SeatingChart from "src/pages/seatingChart/SeatingChart";
import NoticeList from "src/pages/notice/list/NoticeList";
import NoticeDetail from "src/pages/notice/detail/NoticeDetail";
import TermsOfUse from "src/pages/footer/TermsOfUse";
import PrivacyPolicy from "src/pages/footer/PrivacyPolicy";
import OperationPolicy from "src/pages/footer/OperationPolicy";
import AccessLog from "src/pages/mypage/contract/user/access-log";
import InvitationComplete from "src/pages/visitor/invitation/InvitationComplete";
import ContractPaymentDetail from "src/pages/mypage/contract/payment/detail/ContractPaymentDetail";
import ContractPaymentList from "src/pages/mypage/contract/payment/list/ContractPaymentList";
import PaymentComplete from "src/pages/mypage/contract/complete/PaymentComplete";
import FacilityTime from "src/pages/mypage/contract/facilityTime/FacilityTime";
import BuildingDetail from "src/pages/building/detail/BuildingDetail";
import FacilityDetail from "src/pages/product/detail/FacilityDetail";
import Soon from "src/pages/main/Soon";
import { pagePath } from ".";
import Inquiry from "src/pages/cs/Inquiry";
import ChangeCarNumber from "src/pages/visitor/invitation/ChangeCarNumber";
import ContractGuideList from "src/pages/contractGuide/list/ContractGuideList";
import ContractGuideDetail from "src/pages/contractGuide/detail/ContractGuideDetail";
import PublicContractGuideList from "src/pages/contractGuide/list/PublicContractGuideList";
import PublicContractGuideDetail from "src/pages/contractGuide/detail/PublicContractGuideDetail";
import Meaning from "src/pages/mypage/profile/withdrawal/Withdrawal";
import WithdrawalComplete from "src/pages/mypage/profile/withdrawal/WithdrawalComplete";
import Withdrawal from "src/pages/mypage/profile/withdrawal/Withdrawal";
import ExistReservation from "src/pages/mypage/profile/withdrawal/ExistReservation";

//신청 계약 상세 S2 상품 유의시항 = ServiceTypeGuidelines
import ServiceTypeGuidelines from "src/pages/mypage/contract/serviceTypePayment/components/guide/ServiceTypeGuidelines";
//신청 계약 상세 S2 상품 결제 및 취소 안내 = ServiceTypeRefundPolicy
import ServiceTypeRefundPolicy from "src/pages/mypage/contract/serviceTypePayment/components/guide/ServiceTypeRefundPolicy";
//신청 계약 상세 S2 상품 판매자 정보 = SellerModal
import SellerModal from "src/pages/mypage/contract/serviceTypePayment/components/guide/SellerModal";
//신청 계약 상세 S2 상품 현금영수증 및 세금계산서 발금안내 = NotionModal
//신청 계약 상세 S2 상품 개인정보 제3자 제공 동의 = NotionModal
//신청 계약 상세 S2 상품 개인정보 수집 및 이용 동의 = NotionModal
import NotionModal from "src/pages/mypage/contract/serviceTypePayment/components/guide/NotionModal";

import CashBillInfo from "src/pages/mypage/contract/serviceTypePayment/components/switchInvoice/CashBillInfo";
import TaxInvoiceInfoEdit from "src/pages/mypage/contract/serviceTypePayment/components/TaxInvoiceInfoEdit";

type CourtRoute = {
  path: string;
  component: React.ComponentType<any> | (() => JSX.Element | null); // 두 가지 타입을 모두 허용
  breadcrumb?: string;
  isProtectRoute?: boolean; // 인증(로그인) 해야만 접근가능한 route 일 경우 true (ProtectedRoute 컴포넌트로 wrapping 됨)
  isDisabledProd?: boolean; // prod 에서는 노출시키지 않는 route 일 경우 true
};

export const routeList: CourtRoute[] = [
  // (무인증 화면) taap 의 universal link 이기 떄문에 핸들링
  { path: "/taap/*", component: Taap },
  // (무인증 화면) 메인화면
  { path: "/court", component: Root },
  { path: "/", component: Root },
  { path: "/court/main", component: Main },
  { path: "/court/soon", component: Soon },
  // (무인증 화면) 로그인
  { path: "/court/login", component: Login, breadcrumb: "로그인" },
  // 문의하기
  { path: "/court/product/:productId/inquiry", component: Inquiry },

  /* 약관 */
  { path: "/court/login/termsOfUse", component: TermsOfUse, breadcrumb: "이용약관" },
  { path: "/court/login/privacyPolicy", component: PrivacyPolicy, breadcrumb: "개인정보처리방침" },
  { path: "/court/login/operationPolicy", component: OperationPolicy, breadcrumb: "운영정책" },
  /* 건물 */
  { path: "/court/building/:buildingId", component: BuildingDetail, breadcrumb: "건물" },
  /* 마이페이지 */
  // 신청/계약 목록
  { path: "/court/mypage/contracts", component: ContractList, breadcrumb: "신청/계약", isProtectRoute: true },
  // 신청/계약 상세
  {
    path: "/court/mypage/contracts/:contractId",
    component: ContractDetail,
    breadcrumb: "상세 내역",
    isProtectRoute: true,
  },

  // 신청/계약 상세 > 공용공간 이용시간
  {
    path: "/court/mypage/contracts/:contractId/facilityTime",
    component: FacilityTime,
    breadcrumb: "공용공간 이용시간",
    isProtectRoute: true,
  },
  // 신청/계약 상세 > 유의시항
  {
    path: "/court/mypage/contracts/:contractId/serviceTypeGuidelines",
    component: ServiceTypeGuidelines,
    breadcrumb: "유의시항",
    isProtectRoute: true,
  },
  // 신청/계약 상세 > 결제 및 취소 안내
  {
    path: "/court/mypage/contracts/:contractId/ServiceTypeRefundPolicy",
    component: ServiceTypeRefundPolicy,
    breadcrumb: "결제 및 취소 안내",
    isProtectRoute: true,
  },
  // 신청/계약 상세 > 판매자 정보
  {
    path: "/court/mypage/contracts/:contractId/SellerModal",
    component: SellerModal,
    breadcrumb: "판매자 정보",
    isProtectRoute: true,
  },
  // 신청/계약 상세 > 현금영수증 및 세금계산서 발금안내, 개인정보 제3자 제공 동의, 개인정보 수집 및 이용 동의
  {
    path: "/court/mypage/contracts/:contractId/NotionModal",
    component: NotionModal,
    breadcrumb: "유의시항",
    isProtectRoute: true,
  },
  {
    path: "/court/mypage/contracts/:contractId/CashBillInfoEdit",
    component: CashBillInfo,
    breadcrumb: "현금영수증 발급 정보 수정",
    isProtectRoute: true,
  },
  {
    path: "/court/mypage/contracts/:contractId/TaxInvoiceInfoEdit",
    component: TaxInvoiceInfoEdit,
    breadcrumb: "세금계산서 발급정보수정",
    isProtectRoute: true,
  },

  // 신청/계약 상세 > 이용자 목록
  {
    path: "/court/mypage/contracts/:contractId/users",
    component: UserList,
    breadcrumb: "이용자 목록",
    isProtectRoute: true,
  },
  // 신청/계약 상세 > 이용자 목록 > 이용자 초대
  {
    path: "/court/mypage/contracts/:contractId/users/invite",
    component: UserInvite,
    breadcrumb: "이용자 초대",
    isProtectRoute: true,
  },
  // 신청/계약 상세 > 이용자 목록 > 이용자 초대 > 실패 내역
  {
    path: "/court/mypage/contracts/:contractId/users/invite/fail",
    component: UserInviteFail,
    breadcrumb: "실패 내역",
    isProtectRoute: true,
  },
  {
    path: "/court/mypage/contracts/:contractManageId/users/:contractApplyNumber",
    component: AccessLog,
    breadcrumb: "출입 기록 보기",
    isProtectRoute: true,
  },
  // 신청/계약 상세 > 청구 내역 목록
  {
    path: "/court/mypage/contracts/:contractId/bills",
    component: ContractBillList,
    breadcrumb: "청구 내역",
    isProtectRoute: true,
  },
  // 신청/계약 상세 > 청구 내역 상세
  {
    path: "/court/mypage/contracts/:contractId/bills/:billOrder",
    component: ContractBillDetail,
    breadcrumb: "월별 청구내역",
    isProtectRoute: true,
  },
  // 신청/계약 상세 > 이용료 납부 일정
  {
    path: "/court/mypage/contracts/:contractId/paymentSchedule",
    component: PaymentScheduleList,
    breadcrumb: "이용료 납부 일정",
    isProtectRoute: true,
  },

  // (무인증 화면) 방문자 초대 상세
  {
    path: "/court/visitor/detail/:visitorKey",
    component: VisitorDetail,
  },
  // (무인증 화면) 방문 초대자용 방문자 초대 상세
  {
    path: "/court/visitor/invitation/:invitationKey",
    component: InvitationDetail,
  },
  // (무인증 화면) 방문자 셀프 등록
  {
    path: "/court/visitor/form",
    component: VisitorForm,
  },
  // (무인증 화면) 방문자 셀프 등록 완료
  {
    path: "/court/visitor/complete",
    component: InvitationComplete,
  },
  // (무인증 화면) 차량 번호 변경
  {
    path: "/court/visitor/changeCarNumber/:visitorKey",
    component: ChangeCarNumber,
  },
  //  상품 이용안내 목록 ** 접근 가능한 페이지가 없어 주석처리
  // {
  //   path: "/court/product/:productId/guides",
  //   component: ProductGuideList,
  //   isProtectRoute: true,
  // },
  // 상품 이용안내 상세
  // {
  //   path: "/court/product/:productId/guides/:guideId",
  //   component: ProductGuideDetail,
  //   isProtectRoute: true,
  // },
  // (무인증 화면) 상품 이용안내 목록
  {
    path: "/court/product/:productId/public/guides",
    component: PublicProductGuideList,
  },
  // (무인증 화면) 상품 이용안내 상세
  {
    path: "/court/product/:productId/public/guides/:guideId",
    component: PublicProductGuideDetail,
  },
  // 계약 이용안내 목록
  {
    path: "/court/contractManage/:contractManageId/guides",
    component: ContractGuideList,
    isProtectRoute: true,
  },
  // 계약 이용안내 상세
  {
    path: "/court/contractManage/:contractManageId/guides/:guideId",
    component: ContractGuideDetail,
    isProtectRoute: true,
  },
  // (무인증 화면) 계약 이용안내 목록
  {
    path: "/court/contractManage/:contractManageId/public/guides",
    component: PublicContractGuideList,
  },
  // (무인증 화면) 계약 이용안내 상세
  {
    path: "/court/contractManage/:contractManageId/public/guides/:guideId",
    component: PublicContractGuideDetail,
  },
  // (무인증 화면) 좌석배치도
  {
    path: "/court/seatingChart",
    component: SeatingChart,
  },
  /* 공지사항 */
  // 공지사항 목록
  {
    path: "/court/notices",
    component: NoticeList,
  },
  // 공지사항 상세
  {
    path: "/court/notices/:noticeId",
    component: NoticeDetail,
  },

  /* 에러페이지 */
  {
    path: "/court/error/page-not-found",
    component: PageNotFound,
  },
  {
    path: "/court/error/system-check",
    component: SystemCheck,
  },
  {
    path: "/court/error/network-failure",
    component: NetworkFailure,
  },
  {
    path: "/court/error/system-error",
    component: SystemError,
  },
  {
    path: "/court/error/page-not-allowed",
    component: PageNotAllowed,
  },

  // 회원가입 > 이메일 등록
  {
    path: "/court/join/email",
    component: RegisterEmail,
    isProtectRoute: true,
    isDisabledProd: false,
  },
  // 회원가입 > 완료
  {
    path: "/court/join/complete",
    component: JoinComplete,
    isProtectRoute: true,
    isDisabledProd: false,
  },
  // 로그인 후 마이페이지
  {
    path: "/court/mypage/main",
    component: MyPageMain,
    isProtectRoute: true,
    // isDisabledProd: true,
  },
  // 마이페이지 > 프로필 편집
  {
    path: "/court/mypage/profile",
    component: ProfileEdit,
    isProtectRoute: true,
    // isDisabledProd: false,
  },
  // 마이페이지 > 프로필 편집 > 회원탈퇴
  {
    path: "/court/mypage/profile/withdrawal",
    component: Withdrawal,
    isProtectRoute: true,
    // isDisabledProd: false,
  },
  // 마이페이지 > 프로필 편집 > 회원탈퇴 완료
  {
    path: "/court/mypage/profile/withdrawalComplete",
    component: WithdrawalComplete,
    // isDisabledProd: false,
  },
  // 마이페이지 > 프로필 편집 > 회원탈퇴 > 유효한 계약이 있는 페이지
  {
    path: "/court/mypage/profile/withdrawalReservation",
    component: ExistReservation,
    isProtectRoute: true,
    // isDisabledProd: false,
  },
  // 마이페이지 > 프로필 편집 > 이메일 인증
  {
    path: "/court/mypage/profile/email",
    component: EmailVerify,
    isProtectRoute: true,
    // isDisabledProd: true,
  },
  // 마이페이지 > 프로필 편집 > 휴대폰 변경
  {
    path: "/court/mypage/profile/phone",
    component: PhoneEdit,
    isProtectRoute: true,
    // isDisabledProd: true,
  },
  // (무인증 화면) 공간상품 목록
  {
    path: "/court/product",
    component: Root,
    // isDisabledProd: true,
  },
  // (무인증 화면) 공간상품 상세
  {
    path: "/court/product/:productId",
    component: ProductDetail,
  },
  // (무인증 화면) 공간예약 상세
  {
    path: "/court/product/:productId/facility/:facilityId",
    component: FacilityDetail,
  },
  // 공간상품: 이용신청 (견적서) (T타입 전용)
  {
    path: pagePath.quotation,
    component: ContractApply,
    isProtectRoute: true,
  },
  // 공간상품: 이용신청 완료
  {
    path: "/court/mypage/contracts/:contractId/accept",
    component: ContractComplete,
    isProtectRoute: true,
  },

  // 마이페이지 > 신청/계약 상세 > 결제 내역 / 연체 내역 목록
  {
    path: "/court/mypage/contracts/:contractId/payments",
    component: ContractPaymentList,
    isProtectRoute: true,
    // isDisabledProd: true,
    breadcrumb: "결제 내역",
  },

  // 마이페이지 > 신청/계약 상세 > 결제 내역 / 연체 내역 > 상세
  {
    path: "/court/mypage/contracts/:contractId/payments/:billId",
    component: ContractPaymentDetail,
    isProtectRoute: true,
    // isDisabledProd: true,
    breadcrumb: "결제 내역 상세",
  },

  // 마이페이지 > 신청/계약 상세 > 신용카드 등록 / 결제 완료 상태 페이지
  {
    path: "/court/payment/result",
    component: RegisterCreditcardComplete,
    // isProtectRoute: true,
    // isDisabledProd: true,
  },
  // // 신청/계약 상세 > 결제 완료
  // {
  //   path: "/court/payletter/complete/creditcard/payment",
  //   component: PaymentComplete,
  //   // isProtectRoute: true,
  //   isDisabledProd: true,
  // },
];
