const RefundPolicy = ({ type, refundInfo }: any) => {
  console.log("RefundPolicy refundInfo", refundInfo);
  return (
    <>
      {refundInfo && refundInfo.initValue !== undefined && refundInfo.initValue !== "" && (
        <section className="base-section-layout w-100">
          <div className="base-section-layout__container">
            <div className="base-section-layout__wrap">
              <div className="base-section-layout__title-wrap">
                <h2>{"결제 및 취소 안내"}</h2>
              </div>
              <div>
                {refundInfo.initValue.split("\n").map((line: any, idx: number) => {
                  return (
                    <span key={idx}>
                      <p className="font14">{line}</p>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default RefundPolicy;
