import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "src/components/layout/Header";
import BaseFullModal from "src/components/BaseFullModal";

type Props = {
  type: string;
  onClose: any;
};
type ModalType = { type: string; label: string; url: string };
const modalType: Array<ModalType> = [
  { type: "receipt", label: "현금영수증 및 세금계산서 발급 안내", url: "https://guidelines.taapspace.kr/terms/taapspace/receipt" },
  { type: "reservation", label: "개인정보 제3자 제공 동의", url: "https://guidelines.taapspace.kr/terms/taapspace/privacy-policy/space-reservation" },
  {
    type: "support",
    label: "개인정보 수집 및 이용동의",
    url: "https://guidelines.taapspace.kr/terms/taapspace/privacy-policy/customer-support",
  },
];

const NotionModalComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  // 뒤로가기
  const onClose = () => {
    navigate(-1);
  };

  const [modalData, setModalData] = useState<ModalType>();
  useEffect(() => {
    const data = modalType.find((item) => item.type === type);
    setModalData(data);
  }, [type]);
  return (
    <div className="contract-page">
      <Header
        headerType="BACK"
        title={modalData?.label}
        onClickBackButton={() => {
          onClose()!;
        }}
      />
      {/* url 데이터 보여주기 */}
      <div className="notion-modal">
        <iframe src={modalData?.url} className="notion-modal__iframe"></iframe>
      </div>
    </div>
  );
};
export default NotionModalComponent;
