import { PriceRowProps } from "../../components/types";
import { numberToStringWithComma } from "src/utils/common-util";

export const PriceRow = ({ label, value, className = "", isTotal = false }: PriceRowProps) => {
  return (
    <div className="base-section-layout-line">
      <p className="base-section-layout-index text-primary3">{label}</p>
      <span className={className}>
        {isTotal && <span className="base-section-layout-value totalPrice font18">{numberToStringWithComma(value)}</span>}
        {!isTotal && <span className="base-section-layout-value">{numberToStringWithComma(value)}</span>}
        <span className="font14"> 원</span>
      </span>
    </div>
  );
};
