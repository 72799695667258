import { useCallback, useState } from "react";
import BaseFullModal from "src/components/BaseFullModal";
import ServiceTypeGuidelines from "./guide/ServiceTypeGuidelines";
import ServiceTypeRefundPolicy from "./guide/ServiceTypeRefundPolicy";
import NotionModal from "./guide/NotionModal";
import SellerModal from "./guide/SellerModal";
import { ContractStep } from "../../../../../api/contract/contract-types";
import { useNavigate, useParams } from "react-router-dom";

interface ServiceTypeGuideProps {
  guideInfo: any;
  refundInfo: any;
  sellerData: any;
  contractStatus: ContractStep;
}

const ServiceTypeGuide = ({ guideInfo, refundInfo, sellerData, contractStatus }: ServiceTypeGuideProps) => {
  const { contractId } = useParams();
  const navigate = useNavigate();

  // url params 통해 props 전달
  const handleNavigateNotionModal = (type: string) => {
    navigate(`/court/mypage/contracts/${contractId}/NotionModal?type=${type}`);
  };
  const handleNavigateRefundPolicy = () => {
    navigate(`/court/mypage/contracts/${contractId}/ServiceTypeRefundPolicy?data=${encodeURIComponent(JSON.stringify(refundInfo))}`);
  };
  const handleNavigateGuide = () => {
    navigate(`/court/mypage/contracts/${contractId}/ServiceTypeGuidelines?data=${encodeURIComponent(JSON.stringify(guideInfo))}`);
  };
  const handleNavigateSeller = () => {
    navigate(`/court/mypage/contracts/${contractId}/SellerModal?data=${encodeURIComponent(JSON.stringify(sellerData))}`);
  };
  return (
    <section className="base-section-layout top-divider">
      <div className="base-section-layout__wrap">
        {guideInfo?.initValue && (
          <div className="base-section-layout-line">
            <div className="base-section-layout-index cursor-pointer w-100">
              <div className="mt15 no-wrap" onClick={() => handleNavigateGuide()}>
                <div className="flex-center-between">
                  <span>유의사항</span>
                  <div className="arrow-right"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {refundInfo?.initValue && (
          <div className="base-section-layout-line">
            <div className="base-section-layout-index cursor-pointer w-100">
              <div className="mt15 no-wrap" onClick={() => handleNavigateRefundPolicy()}>
                <div className="flex-center-between">
                  <span>결제 및 취소 안내</span>
                  <div className="arrow-right"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="base-section-layout-line">
          <div className="base-section-layout-index cursor-pointer w-100">
            <div className="mt15 no-wrap" onClick={() => handleNavigateSeller()}>
              <div className="flex-center-between">
                <span>판매자 정보</span>
                <div className="arrow-right"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="base-section-layout-line">
          <div className="base-section-layout-index cursor-pointer w-100">
            <div className="mt15 no-wrap" onClick={() => handleNavigateNotionModal("receipt")}>
              <div className="flex-center-between">
                <span>현금영수증 및 세금계산서 발급안내</span>
                <div className="arrow-right"></div>
              </div>
            </div>
          </div>
        </div>
        {String(contractStatus) === "CONTRACT_ACCEPT" && (
          <div className="base-section-layout-line">
            <div className="base-section-layout-index cursor-pointer w-100">
              <div className="mt15 no-wrap" onClick={() => handleNavigateNotionModal("reservation")}>
                <div className="flex-center-between">
                  <span>개인정보 제3자 제공 동의</span>
                  <div className="arrow-right"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {String(contractStatus) === "CONTRACT_ACCEPT" && (
          <div className="base-section-layout-line">
            <div className="base-section-layout-index cursor-pointer w-100">
              <div className="mt15 no-wrap" onClick={() => handleNavigateNotionModal("support")}>
                <div className="flex-center-between">
                  <span>개인정보 수집 및 이용 동의</span>
                  <div className="arrow-right"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* {guideOpen && <BaseFullModal isOpen={guideOpen} children={<ServiceTypeGuidelines guideInfo={guideInfo} onClose={closeGuide} />} />}
      {guideRefund && <BaseFullModal isOpen={guideRefund} children={<ServiceTypeRefundPolicy refundInfo={refundInfo} onClose={closeRefund} />} />}
      {guideSellerFlag && <BaseFullModal isOpen={guideSellerFlag} children={<SellerModal sellerData={sellerData} onClose={closeSeller} />} />}
      {guideProofFlag && <BaseFullModal isOpen={guideProofFlag} children={<NotionModal type="receipt" onClose={closeProof} />} />}

      {guideThirdFlag && <BaseFullModal isOpen={guideThirdFlag} children={<NotionModal type="reservation" onClose={closeThird} />} />}
      {guideIndividualFlag && <BaseFullModal isOpen={guideIndividualFlag} children={<NotionModal type="support" onClose={closIndividual} />} />} */}
    </section>
  );
};

export default ServiceTypeGuide;
