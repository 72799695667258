import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getContractAsync } from "src/api/contract/contract-api";
import { ContractModel } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getQuotationListAsync } from "src/api/reservation/reservation-api";
import { QuotationItem } from "src/api/reservation/reservation-type";
import { BaseBottomSheet } from "src/components";
import Header from "src/components/layout/Header";
import MetaTag from "src/components/layout/MetaTag";
import useProductFacility from "src/pages/product/hooks/useProductFacility";
import { getUseTimeDiff, onChangeKoreanDays } from "src/utils/common-util";
import noImage from "src/assets/images/noImage_default.svg";

const s3url = process.env.REACT_APP_S3_CONTENT_BASEURL;

/**
 * 이용신청 > 완료 화면
 */
const ContractComplete = () => {
  const navigate = useNavigate();

  // path variable
  const { contractId } = useParams();

  const [isCompleteContractBottomSheetOpen, setIsCompleteContractBottomSheetOpen] = useState(false);

  // 신청/계약 상세
  const [contract, setContract] = useState<ContractModel | null>(null);

  const [quotation, setQuotation] = useState<QuotationItem & { createdDate: string }>();

  const billDueDate = useMemo(() => {
    const schedule = contract?.scheduleList.find((item) => item.supplyType === "RENTAL");

    const dueDate = schedule?.recentBill?.billDueDate;
    return dueDate;
  }, [contract?.scheduleList]);

  const quotationNo: string = useMemo(() => {
    const quotationList = contract?.quotationList || [];
    let _quotationNo;

    // TODO: 계속 quotationList 는 1개??
    if (quotationList.length > 0) {
      _quotationNo = quotationList[0].quotationNo;

      // const recentDateObj: Quotation = quotationList?.reduce((recent, current) => {
      //   const currentDate = moment(current.createdDate);
      //   const recentDate = moment(recent.createdDate);

      //   return currentDate.isAfter(recentDate) ? current : recent;
      // });
      // _quotationNo = recentDateObj.quotationNo;
    }

    return _quotationNo || "";
  }, [contract?.quotationList]);

  const { facility, building } = useProductFacility({
    productId: String(quotation?.productId || ""),
    facilityId: String(quotation?.buildingCommonFacilityId || ""),
  });

  // 신청/계약 상세 조회 api
  const { executeAsync: getContract } = useApiOperation(getContractAsync);

  const { executeAsync: getQuotaion } = useApiOperation(getQuotationListAsync);

  // 신청/계약 상세 조회
  const fetchContract = useCallback(
    async (contractId: string) => {
      const { data } = await getContract({ contractId });
      setContract(data?.data || null);
    },
    [getContract],
  );

  useEffect(() => {
    if (contractId) {
      fetchContract(contractId);
    }
  }, [contractId]);

  const fetchQuotationDetail = async () => {
    const { data, status } = await getQuotaion({ quotationNo });
    if (status >= 200 && status <= 299) {
      const result = data.data.content[0];

      setQuotation({ ...result.quotationItemList[0], createdDate: result.createdDate });
    }
  };

  useEffect(() => {
    if (quotationNo) {
      fetchQuotationDetail();
    }
  }, [quotationNo]);
  // 마이페이지 > 신청계약 내역 화면으로 이동
  const goContracts = () => {
    // navigate(`/court/mypage/contracts/${}`);
    navigate(`/court/mypage/contracts/${contractId}`);
  };

  // if (contract?.spaceProductType === "FULL_COURT" || contract?.spaceProductType === "OPEN_COURT") {
  // if (contract?.spaceProductType !== "TIME_COURT") {
  //   return (
  //     <>
  //       <Header />
  //       <MetaTag title="예약완료 | TaapSpace" />

  //       <div className="contract-page">
  //         <article>
  //           <section className="contract-main">
  //             <h1 className="message">이용신청이 접수되었습니다.</h1>
  //             <p className="application-number">신청번호 : {contract?.contractApplyNumber}</p>
  //             <p className="desc border-bottom">
  //               COURT 담당자가 신청 사항을 검토하여 회원정보에 등록하신 연락처로 이용 안내 예정입니다. 자세한 사항은 신청/계약 내역에서 확인 하실 수
  //               있습니다.
  //             </p>
  //           </section>
  //           <div className="btn-wrap">
  //             <button
  //               className="base-btn"
  //               onClick={() => {
  //                 goContracts();
  //               }}
  //             >
  //               신청/계약 내역
  //             </button>
  //           </div>
  //         </article>
  //       </div>
  //     </>
  //   );
  // } else if (contract?.spaceProductType === "TIME_COURT") {
  return (
    <>
      <Header />
      <MetaTag title="예약완료 | TaapSpace" />
      <div className="contract-page">
        <article>
          <section className="contract-main">
            <p className="font16">예약 완료!</p>
            <h1 className="message font24 mb30 mt5 pa0">{`${moment(billDueDate).format("HH시 mm분")}까지 결제해주세요.`}</h1>
            <p className="font14 text-primary3 mb5">공간 예약이 완료되었습니다.</p>
            <p className="font14 text-primary3">결제기한 내 결제되지 않으면 해당 예약건은 취소됩니다.</p>
          </section>

          <section className="contract-main py0">
            <div className="gray-card">
              <div className="gray-card__product">
                <img
                  className="minmax60"
                  src={
                    facility?.mediaList && facility?.mediaList.length > 0
                      ? facility.mediaList.find((media) => media.isPrimary)?.url || facility?.mediaList![0].url
                      : noImage
                  }
                  alt="공용공간 대표이미지"
                />
                <div className="gray-card__contents">
                  <p className="gray-card__contents-title">{facility?.facilityName}</p>
                  <p className="gray-card__contents-address">{building.buildingName}</p>
                </div>
              </div>
              <div className="gray-card__info">
                <>
                  <div className="base-section-layout-line">
                    <p className="base-section-layout-index text-primary3">이용기간</p>
                    <p className="base-section-layout-value">{`${
                      quotation?.startDate && moment(quotation?.startDate).format("YYYY년 MM월 DD일")
                    } ${onChangeKoreanDays(quotation?.endDate)}요일`}</p>
                  </div>
                  <div className="base-section-layout-line">
                    <p className="base-section-layout-index text-primary3">이용시간</p>
                    <p className="base-section-layout-value">
                      {`${quotation?.startDate && moment(quotation?.startDate).format("HH:mm")} ~
                      ${quotation?.endDate && moment(quotation?.endDate).format("HH:mm")}
                      ${getUseTimeDiff(quotation?.startDate, quotation?.endDate)}`}
                    </p>
                  </div>
                  <div className="base-section-layout-line">
                    <p className="base-section-layout-index text-primary3">신청번호</p>
                    <p className="base-section-layout-value">{contract?.contractApplyNumber}</p>
                  </div>
                  <div className="base-section-layout-line">
                    <p className="base-section-layout-index text-primary3">신청일시</p>
                    <p className="base-section-layout-value">{moment(quotation?.createdDate).format("YYYY년 MM월 DD일 HH:mm")}</p>
                  </div>
                </>
              </div>
            </div>
          </section>
          {/* <section className="contract-application"></section> */}
          <div className="btn-wrap">
            <button
              className="base-btn"
              onClick={() => {
                goContracts();
              }}
            >
              결제하기
            </button>
          </div>
          <BaseBottomSheet isOpen={isCompleteContractBottomSheetOpen} onClose={() => setIsCompleteContractBottomSheetOpen(false)}>
            <div className="taap-appstore-sheet">
              <div className="main-text">
                <span>Play work with Taap</span>
              </div>
              <div className="contents">
                <img src={`${s3url}taap/images/app_icon.png`} />
                <p>
                  출입부터 회의실 예약, 불편사항 접수까지
                  <br /> Taap 하나로 기분 좋은 오피스 생활을 하세요.
                </p>
              </div>
              <div className="btn-wrap">
                <button className="app-btn app-google mr10"></button>
                <button className="app-btn app-apple"></button>
              </div>
            </div>
          </BaseBottomSheet>
        </article>
      </div>
    </>
  );
  // }

  // return null;
};

export default ContractComplete;
