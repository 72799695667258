import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getPublicMediaFilesAsync } from "src/api/file/file-api";
import { MediaFile } from "src/api/file/file-types";
import { useApiOperation } from "src/api/hooks";
import { getPublicNoticeListAsync } from "src/api/notice/notice-api";
import { Notice, NoticeListParams } from "src/api/notice/notice-types";
import { getSearchProductListAsync } from "src/api/product/product-api";
import { ProductListModel, ProductListParams, ProductType, productTypeToString } from "src/api/product/product-types";
import Footer from "src/components/layout/Footer";
import Header from "src/components/layout/Header";
import useIntersectionObserver from "src/pages/hooks/intersection-observer";
import { defaultParams, images } from "src/pages/main/main-types";
import SwiperCore, { Autoplay, Navigation, Pagination, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Tabs, tabOptions } from "./components/Tabs";
import { getKoreanCostUnits } from "src/utils/common-util";
import { uniqueId } from "lodash";
import qs from "qs";
/**
 * 메인 화면
 */
type Media = { url: string };
type QueryParams = {
  partnerCode?: string;
};
interface ProductListModelForFront extends ProductListModel {
  mediaList?: Media[];
}
const isOnlyServePage = process.env.REACT_APP_ONLY_SERVE_PAGE;

const Main = () => {
  SwiperCore.use([Autoplay, Parallax, Navigation, Pagination]);

  const [productList, setProductList] = useState<ProductListModelForFront[]>([]);
  const [noticeList, setNoticeList] = useState<Notice[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<string>("TIME_COURT");
  const location = useLocation();
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    return _queryParams;
  }, [location.search]);

  const searchParams = new URLSearchParams(location.search);
  const partnerCode = searchParams.get("partnerCode");

  const navigate = useNavigate();
  // (무인증) 상품목록 api
  const { executeAsync: getProductList, loading: isFetchProductListLoading } = useApiOperation(getSearchProductListAsync, {
    noAuthenticationRequired: true,
  });
  // (무인증) 공지사항 목록 api
  const { executeAsync: getPublicNotices } = useApiOperation(getPublicNoticeListAsync, { noAuthenticationRequired: true });

  // 무인증 상품목록 호출
  const fetchProductList = useCallback(
    async (params: ProductListParams) => {
      const { data, status } = await getProductList({
        ...params,
        sort: {
          orders: [
            {
              property: "modifiedDate",
              direction: "DESC",
            },
          ],
        },
      });
      if (status >= 200 && status <= 299) {
        const productListData = data?.data?.content;

        setProductList(productListData);
        setTotalPages(Number(data?.meta?.pageMeta?.totalPages || 0));
      }
    },
    [getProductList],
  );

  // 공지사항 호출
  const fetchNoticeList = useCallback(
    async (params: NoticeListParams) => {
      const { data, status } = await getPublicNotices(params);
      if (status >= 200 && status <= 299) {
        setNoticeList(data?.data?.rows || []);
      }
    },
    [getPublicNotices],
  );

  // 상품 목록 더 불러오기
  const loadMoreProductList = useCallback(async () => {
    if (productList.length > 0) {
      const nextPage = page + 1;
      setPage(nextPage);
      const payload = {
        ...defaultParams,
        productType: selectedTab as ProductType,
        page: nextPage,
        sort: {
          orders: [
            {
              property: "modifiedDate",
              direction: "DESC",
            },
          ],
        },
      };
      const { productType, ...rest } = payload;
      if (!selectedTab) {
        const { data } = await getProductList({ ...rest });
        const productListData = data?.data?.content;
        setProductList([...productList, ...(productListData || [])]);
      } else {
        const { data } = await getProductList(payload);
        const productListData = data?.data?.content;
        setProductList([...productList, ...(productListData || [])]);
      }
    }
  }, [page, productList, getProductList, selectedTab]);

  // 스크롤 감지
  const onIntersect: IntersectionObserverCallback = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      // console.log("감지결과 :", entry.isIntersecting);
      if (entry.isIntersecting && !isFetchProductListLoading && page < totalPages) {
        loadMoreProductList();
      }
    },
    [loadMoreProductList, isFetchProductListLoading, page, totalPages],
  );

  // 교차 감지 hook (scroll 감지)
  const { setTarget } = useIntersectionObserver({
    onIntersect,
    threshold: [0],
  });

  useEffect(() => {
    fetchProductList({
      ...defaultParams,
      productType: "TIME_COURT",
      partnerCode: queryParams?.partnerCode,
    });
    fetchNoticeList({ ...defaultParams });
  }, [fetchProductList, fetchNoticeList]);

  return (
    <>
      <Header />
      <section className="slider main">
        <Swiper
          pagination={{
            clickable: true, // 버튼 클릭 여부
            dynamicBullets: true,
            dynamicMainBullets: 3,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          watchOverflow={true}
          parallax={true}
          className="main-swiper"
        >
          {images.map((item, i) => {
            return (
              <SwiperSlide key={item.pic + i}>
                <div
                  className="slider-bg"
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.36) 66.5%, rgba(0, 0, 0, 0.6) 100%), url(${item.pic})`,
                  }}
                  data-swiper-parallax={"23%"}
                >
                  <h1 className="main-slider-box-title" data-swiper-parallax="-300">
                    {item.title}
                  </h1>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
      {noticeList.length > 0 ? (
        <Link to={`/court/notices/${noticeList?.[0]?.id}?page=public`}>
          <section className="notice-area">
            <span>공지</span>
            <p>{noticeList?.[0]?.title || "-"}</p>
          </section>
        </Link>
      ) : (
        <section className="notice-area">
          <span>공지</span>
          <p>-</p>
        </section>
      )}

      <article className="main-contents">
        <section className="main-contents__top">
          <h2>어떤 공간을 찾으시나요?</h2>
          <Tabs
            tabs={tabOptions}
            onSelected={(selected) => {
              setPage(0);
              setSelectedTab(selected);
              setProductList([]);
              const payload = selected
                ? {
                    ...defaultParams,
                    productType: selected as ProductType,
                    ...(partnerCode && { partnerCode }),
                  }
                : { ...defaultParams, ...(partnerCode && { partnerCode }) };
              fetchProductList(payload);
            }}
          />
        </section>
        <section className="space-product">
          <ul>
            {productList.map((product: ProductListModelForFront) => {
              return (
                <Link to={`/court/product/${product.id}`} key={uniqueId()}>
                  <li>
                    <div className={`slider list ${!product?.mediaList || product?.mediaList?.length === 0 ? "noImage" : ""}`}>
                      <Swiper
                        pagination={{
                          clickable: true, // 버튼 클릭 여부
                          dynamicBullets: true,
                          dynamicMainBullets: 3,
                        }}
                        watchOverflow={true}
                        parallax={true}
                        className="list-swiper"
                      >
                        <SwiperSlide>
                          <div
                            className="slider-bg"
                            style={{
                              backgroundImage: `url(${product.primaryImageUrl})`,
                            }}
                            data-swiper-parallax={"23%"}
                          ></div>
                        </SwiperSlide>
                      </Swiper>
                    </div>

                    <div className="information">
                      <div className="inline-wrap">
                        <div className={`space-chip mr8 ${product.productType === "TIME_COURT" ? "color-white" : ""}`}>
                          <span>{productTypeToString(product.productType)}</span>
                        </div>
                        <h2>{product.productName}</h2>
                      </div>
                      <p className="desc">{product?.introduce}</p>
                      <div className="flex-center">
                        <p className="info ic-place">{product?.buildingAddressSummary}</p>
                        {product.productType === "FULL_COURT" ? (
                          <p className="info ic-size ml10">
                            임대 {product?.leasableArea}㎡ / 전용 {product?.leasableAreaNet}㎡
                          </p>
                        ) : (
                          <>{product?.facilityMaxPeopleNums && <p className="info ic-users ml10">최대 {product?.facilityMaxPeopleNums}명</p>}</>
                        )}
                      </div>
                      {!isOnlyServePage && (
                        <>
                          {product.productType === "FULL_COURT" ? (
                            <div className="price-info-container">
                              <div className="price-info">
                                <p className="price">{getKoreanCostUnits(product?.deposit).cost}</p>
                                <p className="text">
                                  {getKoreanCostUnits(product?.deposit).unit ? `${getKoreanCostUnits(product?.deposit).unit}~` : "원~"}{" "}
                                  <span>/보증금</span>
                                </p>
                              </div>
                              <p className="vertical-divider"></p>
                              <div className="price-info">
                                <p className="price">{getKoreanCostUnits(product?.priceMin).cost}</p>
                                <p className="text">
                                  {getKoreanCostUnits(product?.priceMin).unit ? `${getKoreanCostUnits(product?.priceMin).unit}~` : "원~"}
                                  <span>/1개월</span>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="price-info-container">
                                <div className="price-info">
                                  <p className="price">{getKoreanCostUnits(product?.priceMin).cost}</p>
                                  <p className="text">
                                    {getKoreanCostUnits(product?.priceMin).unit ? `${getKoreanCostUnits(product?.priceMin).unit}~` : "원~"}
                                    <span>/1시간</span>
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                </Link>
              );
            })}
          </ul>
        </section>
      </article>
      <div ref={setTarget}></div>
      <Footer />
    </>
  );
};
export default Main;
