import { BaseSelect, BaseTextInput } from "src/components";
import { CashReceiptFormProps } from "../types";
import { onlyNumber, validatePhoneNumberBoolean } from "src/utils/common-util";
import _ from "lodash";

export const CashReceiptForm = ({
  documentProofRequest,
  proofError,
  setDocumentProofRequest,
  setCashErrorFlags,
  handleClickExpenseProofCashBill,
}: CashReceiptFormProps) => {
  return (
    <div className="base-layout-contents">
      <div className="contract-page h-100">
        <div className="container">
          <BaseSelect
            className="identity-type"
            wrapClassName="text-left"
            value={documentProofRequest.identityType}
            options={[
              { label: "개인소득공제(휴대폰)", value: "MOBILE_NUM", type: "INCOME" },
              { label: "개인소득공제(현금영수증카드)", value: "CARD_NUM", type: "INCOME" },
              { label: "사업자증빙용(사업자번호)", value: "CORP_NUM", type: "EXPENSE" },
              { label: "사업자증빙용(현금영수증카드) ", value: "CARD_NUM", type: "EXPENSE" },
            ]}
            getObj={true}
            onChange={(option: any) => {
              const temp = _.cloneDeep(documentProofRequest);
              temp.identityType = option.value;
              temp.tradeUsage = option.type;
              setDocumentProofRequest(temp);
            }}
            placeholder="신청 유형을 입력해 주세요 (필수)"
          />
          {proofError.error.identityType && <p className="mt5 font12 text-red">신청 유형은 필수입니다.</p>}
        </div>
        <div className="container">
          <BaseTextInput
            value={documentProofRequest.identityNum}
            onChange={(value: string | undefined) => {
              const temp = _.cloneDeep(documentProofRequest);
              temp.identityNum = onlyNumber(String(value));
              setDocumentProofRequest(temp);
              if (value) {
                setCashErrorFlags(false, false, false);
              }
            }}
            onBlur={() => {
              const temp = _.cloneDeep(documentProofRequest);
              if (temp.identityType === "MOBILE_NUM" && (temp.identityNum === "" || !validatePhoneNumberBoolean(temp.identityNum))) {
                setCashErrorFlags(true, false, false);
                temp.identityNum = "";
              }
              if (temp.identityType === "CARD_NUM" && (temp.identityNum === "" || temp.identityNum.length < 13 || temp.identityNum.length > 19)) {
                setCashErrorFlags(false, true, false);
                temp.identityNum = "";
              }
              if (temp.identityType === "CORP_NUM" && (temp.identityNum === "" || temp.identityNum.length !== 10)) {
                setCashErrorFlags(false, false, true);
                temp.identityNum = "";
              }
              setDocumentProofRequest(temp);
            }}
            placeholder="숫자만 입력해 주세요 (필수)"
          />
        </div>
      </div>
      <div className="base-floating-btn-wrap px0">
        <div className="container">
          <button
            type="button"
            className="base-btn primary-btn"
            disabled={
              documentProofRequest.identityType === "" ||
              documentProofRequest.identityType === null ||
              documentProofRequest.identityNum === null ||
              documentProofRequest.identityNum === ""
            }
            onClick={() => handleClickExpenseProofCashBill(documentProofRequest)}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};
