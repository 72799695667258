import Header from "src/components/layout/Header";
import RefundPolicy from "./RefundPolicy";
import { useLocation, useNavigate } from "react-router-dom";

const ServiceTypeRefundPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refundInfo = JSON.parse(decodeURIComponent(searchParams.get("data") || "{}"));
  const onClose = () => {
    navigate(-1);
  };
  return (
    <>
      <Header
        headerType="BACK"
        title="결제 및 취소 안내"
        onClickBackButton={() => {
          onClose()!;
        }}
      />
      <RefundPolicy refundInfo={refundInfo} />
    </>
  );
};

export default ServiceTypeRefundPolicy;
