import { PaymentActionButtonProps } from "../../components/types";
import BaseAbstractModal from "src/components/BaseAbstractModal";

export const PaymentActionButton = ({
  isVerifiedPaymentKey,
  isMobile,
  isAsk,
  contract,
  phoneNumber,
  primaryQuotationName,
  pgcode,
  documentProofRequest,
  handleClickPaymentS2,
  setIsAsk,
}: PaymentActionButtonProps) => {
  if (!isVerifiedPaymentKey) {
    return (
      <div className="base-floting-btn-wrap px0">
        <div className="center">
          <div className="flex-center-start mb10">
            <div className="red-bubble"></div>
            <span className="system-red font15 ml5">해당 예약은 현재 결제가 불가능합니다.</span>
          </div>
          <p className="text-gray font14">결제 관련 도움이 필요하시면 아래 버튼을 눌러주세요</p>
          <p className="text-gray font14 mb10">탭 스페이스 결제 관련이라고 말씀하시면 보다 빠르게 도움 드리도록 하겠습니다.</p>
        </div>
        {isMobile ? (
          <a href={`tel:${phoneNumber}`} className="base-btn mt30">
            전화하기
          </a>
        ) : (
          <button type="button" className="base-btn mt30" onClick={() => setIsAsk(true)}>
            문의하기
          </button>
        )}
        {isAsk && (
          <BaseAbstractModal
            isOpen={isAsk}
            children={
              <div className="ask_modal">
                <div className="header_section">문의하기</div>
                <div className="content_section">
                  <div className="text-line flex-center-start">
                    <p className="label-value">신청번호 :</p>
                    <p className="label-value">{contract.contractApplyNumber}</p>
                  </div>
                  <div className="text-line flex-center-start">
                    <p className="label-value">상품명 :</p>
                    <p className="label-value">{contract.spaceProductName}</p>
                  </div>
                  <div className="text-line flex-center-start">
                    <p className="label-value">공용공간 :</p>
                    <p className="label-value">{primaryQuotationName?.facility?.facilityName}</p>
                  </div>
                </div>
                <div className="phone_section">
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </div>
                <div className="footer">
                  <button type="button" className="complete-btn base-btn small-size minmax100" onClick={() => setIsAsk(false)}>
                    확인
                  </button>
                </div>
              </div>
            }
          />
        )}
      </div>
    );
  }

  return (
    <>
      <div className="base-floating-btn-wrap px0">
        <p className="mb10 w-100 font13 text-primary1 font-weight-400 text-center">주문 내용을 확인하고, 결제 및 개인정보 처리에 동의합니다.</p>
        <button type="button" className="base-btn" onClick={() => handleClickPaymentS2(pgcode, documentProofRequest)}>
          결제하기
        </button>
      </div>
    </>
  );
};
